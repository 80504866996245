import { PropsWithChildren } from 'react';

import { IModal } from './interfaces';
import * as Styled from './styled';

export const Modal: React.FC<PropsWithChildren<IModal>> = ({
    children,
    isOpened,
    width,
    handleClose,
    destroyOnClose,
}) => (
    <Styled.Modal
        centered
        closable={false}
        visible={isOpened}
        width={width}
        footer={null}
        zIndex={2000}
        onCancel={handleClose}
        destroyOnClose={destroyOnClose}
    >
        {children}
    </Styled.Modal>
);

Modal.defaultProps = {
    width: 700,
    destroyOnClose: true,
};
