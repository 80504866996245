import { gql } from '@apollo/client';

export const GET_SYSTEM_NOTIFICATION_BY_ID = gql`
    query getSystemNotification($id: String!) {
        getSystemNotification(id: $id) {
            title
            content
            type
            active
            systemNotificationId
        }
    }
`;

export const GET_ALL_SYSTEM_NOTIFICATIONS = gql`
    query getAllSystemNotifications {
        getAllSystemNotifications {
            title
            content
            type
            active
            systemNotificationId
        }
    }
`;

export const CREATE_SYSTEM_NOTIFICATION = gql`
    mutation createSystemNotification($systemNotification: SystemNotificationInputType!) {
        createSystemNotification(systemNotification: $systemNotification) {
            title
            content
            type
            active
            systemNotificationId
        }
    }
`;

export const EDIT_SYSTEM_NOTIFICATION = gql`
    mutation updateSystemNotification($id: String!, $systemNotification: SystemNotificationInputType!) {
        updateSystemNotification(id: $id, systemNotification: $systemNotification) {
            title
            content
            type
            active
            systemNotificationId
        }
    }
`;

export const DELETE_SYSTEM_NOTIFICATION = gql`
    mutation deleteSystemNotification($id: String!) {
        deleteSystemNotification(id: $id)
    }
`;
