import React, { Fragment } from 'react';
import { Routes as Switch, useLocation, RouteObject, Route } from 'react-router-dom';

import { MainLayout, AuthLayout } from 'Layout';
import { SingleChildrenArgumentType } from 'Types';

import { routesConfig, LAYOUTS } from './routesConfig';

const dummy: React.FC<SingleChildrenArgumentType> = ({ children }) => (
    <>
        {children || null}
    </>
);

const useLayout = () => {
    const location = useLocation();
    let layout = dummy;

    const selectedParent = routesConfig.find((route) => {
        const currentRoutePath = route.path === '/' ? '/' : `/${route.path}`;
        return location.pathname.startsWith(currentRoutePath);
    });

    switch (selectedParent?.layout) {
        case LAYOUTS.MAIN:
            layout = MainLayout;
            break;
        case LAYOUTS.AUTH:
            layout = AuthLayout;
            break;
        default:
            break;
    }

    return { layout, isRouteExist: !!selectedParent };
};

const routeRender = (route: RouteObject) => (
    <Fragment key={route.path}>
        {route.index && (
            <Route index element={route.element} />
        )}
        {!route.children && (
            <Route path={route.path} element={route.element} />
        )}
        {route.children && (
            <Route path={route.path} element={route.element} >
                {route.children.map((child) => routeRender(child))}
            </Route>
        )}
    </Fragment>
);

export const Routes = () => {
    const { layout: Layout } = useLayout();

    return (
        <Layout>
            <Switch>
                {routesConfig.map(routeRender)}
            </Switch>
        </Layout>
    );
};
