import * as Yup from 'yup';

import { IValues } from './interfaces';

export const validationSchema = Yup.object().shape({
    username: Yup.string(),
});

export const initialValues: IValues = {
    username: '',
};
