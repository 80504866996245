import { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery, useMutation, QueryResult } from '@apollo/client';

import { PageHeader, Loader } from 'Components';
import {
    GET_DEVICE_AVAILABLE_TOPICS_QUERY,
    GET_DEVICE_TOPIC_TEMPLATE_BY_ID_QUERY,
    UPDATE_DEVICE_TOPIC_TEMPLATE_MUTATION,
} from 'Queries';
import {
    DeviceTemplateTopic,
    GetDeviceTemplateTopicById,
    GetDeviceTemplateTopicByIdVariables,
    GetDeviceTemplateTopics,
    UpdateDeviceTemplateTopicMutation,
    UpdateDeviceTemplateTopicVariables,
} from 'Types';
import { showToastError, showToastSuccess } from 'Utils';

import { FormContent } from './formContent';
import { getInitialValues, validationSchema } from './config';
import { IValues } from './interfaces';
import * as Styled from './styled';

export const EditDeviceTopicTemplate: React.FC = () => {
    const [initialLoading, setInitialLoading] = useState<boolean>(true);
    const navigate = useNavigate();
    const { id: _id } = useParams();

    const { data: topicsData, loading: topicsLoading }: QueryResult<GetDeviceTemplateTopics> = useQuery(GET_DEVICE_AVAILABLE_TOPICS_QUERY, {
        fetchPolicy: 'cache-and-network',
    });
    const topics = topicsData?.getDeviceTemplateTopics || [] as DeviceTemplateTopic[];

    const { data: deviceTopicData, loading: deviceTopicLoading }: QueryResult<
        GetDeviceTemplateTopicById,
        GetDeviceTemplateTopicByIdVariables
    > = useQuery(
        GET_DEVICE_TOPIC_TEMPLATE_BY_ID_QUERY,
        {
            variables: { _id },
            fetchPolicy: 'cache-and-network',
        }
    );
    const deviceTopicTemplate = deviceTopicData?.getDeviceTemplateTopicById;

    const [updateDeviceTopicTemplateMutation] = useMutation<
        UpdateDeviceTemplateTopicMutation,
        UpdateDeviceTemplateTopicVariables
    >(UPDATE_DEVICE_TOPIC_TEMPLATE_MUTATION, {
        refetchQueries: [GET_DEVICE_AVAILABLE_TOPICS_QUERY],
        onCompleted: (data) => {
            if (data?.updateDeviceTemplateTopic) {
                showToastSuccess('Successfully updated');
                navigate(-1);
            } else {
                showToastError('Could not edit a new template');
            }
        },
    });

    useEffect(
        () => {
            if (!topicsLoading && !deviceTopicLoading) {
                setInitialLoading(false);
            }
        },
        [topicsLoading, deviceTopicLoading]
    );

    const navigateBack = () => navigate(-1);

    const onSubmit = async (values: IValues): Promise<void> => {
        const existingTopics = [...topics];
        const currentTopicIndex = existingTopics.findIndex((topic) => topic._id === _id);
        existingTopics.splice(currentTopicIndex, 1);

        const alreadyExists = existingTopics.find((topic) => values.name === topic.name || values.label === topic.label);

        if (alreadyExists) {
            showToastError('Such label or tag already exists');
            return;
        }

        updateDeviceTopicTemplateMutation({ variables: { _id, input: values } });
    };

    if (initialLoading) {
        return <Loader />;
    }

    return (
        <>
            <PageHeader
                title="Add device topic template"
                description={[
                    'The changes you make, will be applied to main service to be displayed in section of adding a new device',
                ]}
            />
            <Styled.Content>
                <Formik
                    initialValues={getInitialValues(deviceTopicTemplate)}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {(props) => (
                        <FormContent
                            {...props}
                            onClickBack={navigateBack}
                            formMode="Edit"
                        />
                    )}
                </Formik>
            </Styled.Content>
        </>
    );
};
