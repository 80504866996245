import { Field } from 'formik';

import { TextInput, Button, Checkbox, BaseFileUploader } from 'Components';
import { generateDeviceNetworkIcon, fileToBase64 } from 'Utils';
import { LTE, LORA } from 'Constants';

import { Sensors } from './sensors';
import { ITemplateForm } from './interfaces';
import * as Styled from './styled';
import { SupportedDevices } from './supportedDevices';

export const FormContent: React.FC<ITemplateForm> = ({ values, isValid, setFieldValue, onClickBack, formMode }) => {
    const fileOnChange = async (file: File) => {
        const result = await fileToBase64(file);
        const image = result as string;
        setFieldValue('imgUrl', image);
    };

    return (
        <Styled.Form>
            <Styled.FormLabel>
                {`${formMode} Shield Template`}
            </Styled.FormLabel>
            <Styled.ContentWrapper>
                <Styled.Section>
                    <Styled.FieldWrapper>
                        <Styled.Title>
                            Name
                        </Styled.Title>
                        <Field
                            name="name"
                            type="text"
                            placeholder="Enter your shield name"
                            component={TextInput}
                            errorPosition="bottom"
                        />
                    </Styled.FieldWrapper>
                    <Styled.FieldWrapper>
                        <Styled.Title>
                            Type
                        </Styled.Title>
                        <Field
                            name="type"
                            type="text"
                            placeholder="Enter your shield type"
                            component={TextInput}
                            errorPosition="bottom"
                        />
                    </Styled.FieldWrapper>
                    <Styled.ButtonsWrapper>
                        <Button buttonType="transparent" onClick={onClickBack}>
                            Back
                        </Button>
                        <Button disabled={!isValid} type="submit">
                            Save
                        </Button>
                    </Styled.ButtonsWrapper>
                </Styled.Section>
                <Styled.Section>
                    <Styled.UploaderContainer>
                        <Styled.Title>
                            Upload image
                        </Styled.Title>
                        <BaseFileUploader
                            expectedExtensions="png"
                            maxSize={4194304}
                            defaultName="shieldImage.png"
                            onChange={fileOnChange}
                        />
                    </Styled.UploaderContainer>
                    <Styled.Networks>
                        {values.networks.map((network, index) => (
                            <Styled.NetworkCheckbox key={index}>
                                <Styled.Network
                                    key={network.key}
                                    $isLTE={network.key === LTE}
                                    $isLora={network.key === LORA}
                                >
                                    {generateDeviceNetworkIcon(network.key)}
                                </Styled.Network>
                                <Field
                                    key={index}
                                    name={`networks.${index}.check`}
                                    component={Checkbox}
                                />
                            </Styled.NetworkCheckbox>
                        ))}
                    </Styled.Networks>
                    <Styled.SettingsWrapper>
                        <Styled.SettingsTitle>
                            Sensors
                        </Styled.SettingsTitle>
                        <Sensors sensors={values.sensors} />
                    </Styled.SettingsWrapper>
                    <Styled.SettingsWrapper>
                        <Styled.SettingsTitle>
                            Supported devices
                        </Styled.SettingsTitle>
                        <SupportedDevices devices={values.supportedDevices}/>
                    </Styled.SettingsWrapper>
                </Styled.Section>
                <Styled.Section>
                    <Styled.ImageWrapper>
                        {values.imgUrl && <Styled.Image src={values.imgUrl} alt="shieldImage" />}
                    </Styled.ImageWrapper>
                </Styled.Section>
            </Styled.ContentWrapper>
        </Styled.Form>
    );
};
