export const ROUTES = {
    auth: {
        login: 'login',
    },
    main: '/',
    devices: {
        main: 'devices',
        deviceTemplates: {
            main: 'device-templates',
            add: 'add',
            edit: 'edit',
        },
        shieldTemplates: {
            main: 'shield-templates',
            add: 'add',
            edit: 'edit',
        },
        deviceTopicTemplates: {
            main: 'device-topic-templates',
            add: 'add',
            edit: 'edit',
        },
    },
    networks: 'networks',
    notifications: 'notifications',
};
