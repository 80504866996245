import { useQuery, QueryResult } from '@apollo/client';
import moment from 'moment';

import { DailyStatsQueries, DailyStatsInputType, DailyDevicesStatsType, DashboardCardStatsQueries, DashboardCardStats } from 'Types';
import { DEVICES_DAILY_STATISTICS_QUERY, ACTIVE_DEVICES_BY_DATERANGES } from 'Queries';

import { NumbersList } from '../../../components';

export const DevicesTextWidget: React.FC = () => {
    const { data: dailyDevicesStatsData }: QueryResult<
        DailyStatsQueries,
        DailyStatsInputType
    > = useQuery(DEVICES_DAILY_STATISTICS_QUERY, {
        variables: {
            days: 30,
        },
    });
    const dailyDevicesStats = dailyDevicesStatsData?.getDailyDevicesStats ?? [] as DailyDevicesStatsType[];

    const { data: activeDevicesdata }: QueryResult<DashboardCardStatsQueries> = useQuery(ACTIVE_DEVICES_BY_DATERANGES);
    const activeDevices = activeDevicesdata?.getActiveDevicesCountsByDate ?? {} as DashboardCardStats;
    const active = {
        title: 'active',
        ...activeDevices,
    };

    const growth = {
        title: 'growth',
        yesterday: dailyDevicesStats[dailyDevicesStats.length - 1]?.growth || 0,
        thirtyDays: 0,
        startOfMonth: 0,
    };
    growth.thirtyDays = dailyDevicesStats.reduce((prev, curr) => prev + curr.growth, 0);
    growth.startOfMonth = dailyDevicesStats
        .filter((obj) => moment(obj.date).isAfter(moment().startOf('month')))
        .reduce((prev, curr) => prev + curr.growth, 0);
    
    return (
        <NumbersList list={[growth, active]} />
    );
};
