import * as Yup from 'yup';

import { DeviceTemplateTopic } from 'Types';

import { IValues } from './interfaces';

export const validationSchema = Yup.object().shape({
    name: Yup.string().required('name is required.'),
    label: Yup.string().required('device type is required.'),
});

export const getInitialValues = (values: Readonly<DeviceTemplateTopic> = null): IValues => ({
    name: values?.name || '',
    label: values?.label || '',
});
