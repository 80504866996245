import { getSimplifiedNumber, getSimplifiedByteNumber } from 'Utils';

import * as Styled from '../../styled';

import { INumbersList } from './interface';

export const NumbersList: React.FC<INumbersList> = ({ list }) => {
    const displayValue = (unit, value) => {
        switch (unit) {
            case 'byte':
                return getSimplifiedByteNumber(value);
            default:
                return getSimplifiedNumber(value);
        }
    };
    return (
        <Styled.DetailsNumbers>
            {list.map((obj) => (
                <Styled.DetailsList key={obj.title}>
                    <Styled.DetailsItem>
                        <Styled.DetailsLabel>
                            {obj.title[0].toUpperCase() + obj.title.slice(1)} (24h)
                        </Styled.DetailsLabel>
                        <Styled.DetailsCount>
                            {displayValue(obj.unit, obj.yesterday)}
                        </Styled.DetailsCount>
                    </Styled.DetailsItem>
                    <Styled.DetailsItem>
                        <Styled.DetailsLabel>
                            {obj.title[0].toUpperCase() + obj.title.slice(1)} (30Days)
                        </Styled.DetailsLabel>
                        <Styled.DetailsCount>
                            {displayValue(obj.unit, obj.thirtyDays)}
                        </Styled.DetailsCount>
                    </Styled.DetailsItem>
                    <Styled.DetailsItem>
                        <Styled.DetailsLabel>
                            {obj.title[0].toUpperCase() + obj.title.slice(1)} (Start of month)
                        </Styled.DetailsLabel>
                        <Styled.DetailsCount>
                            {displayValue(obj.unit, obj.startOfMonth)}
                        </Styled.DetailsCount>
                    </Styled.DetailsItem>
                </Styled.DetailsList>
            ))}
        </Styled.DetailsNumbers>
    );
};
