import * as Yup from 'yup';

import { PlatformNetwork, ShieldTemplate } from 'Types';
import { sortNetworks } from 'Utils';

import { IValues } from './interfaces';

export const validationSchema = Yup.object().shape({
    name: Yup.string().required('name is required.'),
    type: Yup.string().required('device type is required.'),
    sensors: Yup.array(Yup.string()),
    networks: Yup.array(Yup.object().shape({
        key: Yup.string(),
        check: Yup.boolean(),
    })),
    supportedDevices: Yup.array(Yup.string()),
    imgUrl: Yup.string().required('image is required'),
});

export const getInitialValues = (
    networks: PlatformNetwork[],
    values: Readonly<ShieldTemplate> = null
): IValues => ({
    name: values?.name || '',
    type: values?.type || '',
    sensors: values?.sensors || [],
    networks: sortNetworks(networks).map((network) => ({
        key: network.name,
        check: Boolean(values?.supportedNetworks.includes(network.name)),
    })) || [],
    imgUrl: values?.imgUrl || '',
    supportedDevices: values?.supportedDevices || [],
});
