import { useState } from 'react';
import { Form, Field } from 'formik';

import { TextInput, Select, Checkbox, Button, TextArea } from 'Components';

import { IForm } from './interface';
import { SYSTEM_NOTIFICATION_TYPES_VALUES } from './config';
import * as Styled from './styled';

export const FormContent: React.FC<IForm> = ({ handleClose, handleDelete }) => {
    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const onClickDelete = () => {
        setIsDeleting(true);
        setTimeout(() => {
            setIsDeleting(false);
        }, 3500);
    };
    return (
        <Form>
            <Styled.Item>
                <Styled.Label>
                    Title
                </Styled.Label>
                <Field
                    name="title"
                    component={TextInput}
                    errorPosition="bottom"
                />
            </Styled.Item>
            <Styled.Item>
                <Styled.Label>
                    Content
                </Styled.Label>
                <Field
                    name="content"
                    component={TextArea}
                    rows={8}
                    errorPosition="bottom"
                />
            </Styled.Item>
            <Styled.Item>
                <Styled.Label>
                    Type
                </Styled.Label>
                <Field
                    name="type"
                    component={Select}
                    selectedItem={SYSTEM_NOTIFICATION_TYPES_VALUES[1]}
                    values={SYSTEM_NOTIFICATION_TYPES_VALUES}
                    errorPosition="bottom"
                />
            </Styled.Item>
            <Styled.Item>
                <Field
                    name="active"
                    title="Active"
                    component={Checkbox}
                    errorPosition="bottom"
                />
            </Styled.Item>
            <Styled.Row>
                <Button buttonType="transparent" onClick={handleClose}>
                    Cancel
                </Button>
                <Button type="submit">
                    Submit
                </Button>
                {!isDeleting && <Styled.Delete buttonType="error" onClick={onClickDelete}>
                    Delete
                </Styled.Delete>}
                {isDeleting && <Button buttonType="error" onClick={handleDelete}>
                    Confirm
                </Button>}
            </Styled.Row>
        </Form>
    );
};
