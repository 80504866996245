import { useQuery, QueryResult } from '@apollo/client';

import { MESSAGES_COUNT_BY_NETWORKTYPE } from 'Queries';
import { CountByTypeQueries, CountByType } from 'Types';

import { PieChart } from '../../../components';

export const MessagesPieChart: React.FC = () => {
    const { data }: QueryResult<CountByTypeQueries> = useQuery(MESSAGES_COUNT_BY_NETWORKTYPE);
    const countByNetworkType = data?.getMessagesCountByNetworkType ?? [] as CountByType[];

    return (
        <PieChart
            chartData={countByNetworkType}
            nameKey="type"
            dataKey="count"
        />
    );
};
