import { css, ThemeProps } from 'styled-components';

import { Theme } from 'Types';
import { hexToRgb } from 'Utils';

export const shadowSm = css`
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.04),
                0px 0px 2px rgba(0, 0, 0, 0.02);
`;
export const shadowXs = css`
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
`;
export const shadowSmColored = css`
    box-shadow: 0px 2px 8px rgba(${({ theme }: ThemeProps<Theme>) => hexToRgb(theme.colors.active_light)}, 1),
                0px 0px 2px rgba(${({ theme }: ThemeProps<Theme>) => hexToRgb(theme.colors.active_light)}, 0.75);
`;
export const shadowXsColored = css`
    box-shadow: 0px 1px 2px rgba(${({ theme }: ThemeProps<Theme>) => hexToRgb(theme.colors.active_light)}, 1);
`;

export const card = css`
    background: white;
    border-radius: 0.5rem;
    border: 1px solid ${({ theme }: ThemeProps<Theme>) => theme.colors.border};
    ${shadowSm}
`;
