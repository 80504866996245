import { gql } from '@apollo/client';

export const GET_DEVICE_TEMPLATES_QUERY = gql`
    query getDeviceTemplates {
        getDeviceTemplates {
            deviceTemplateTopics {
                _id
                name
                label
            }
            deviceTemplates {
                _id
                name
                ram
                flash
                system
                type
                topic
                imgUrl
                lang
                sensors
                supportedNetworks
            }
        }
    }
`;

export const GET_DEVICE_ALLOWED_NETWORKS_QUERY = gql`
    query getDeviceAllowedNetworks($deviceType: String!) {
        getDeviceAllowedNetworks(deviceType: $deviceType) {
            allowedNetworks
        }
    }
`;

export const GET_DEVICE_TEMPLATE_BY_ID = gql`
    query getDeviceTemplateById($_id: String!) {
        getDeviceTemplateById(_id: $_id) {
            _id
            name
            ram
            flash
            system
            type
            topic
            imgUrl
            lang
            sensors
            supportedNetworks
        }
    }
`;

export const CREATE_DEVICE_TEMPLATE_MUTATION = gql`
    mutation createDeviceTemplate($input: DeviceTemplateInput!) {
        createDeviceTemplate(input: $input) {
            _id
            name
            ram
            flash
            system
            type
            topic
            imgUrl
            lang
            sensors
            supportedNetworks
        }
    }
`;

export const UPDATE_DEVICE_TEMPLATE_MUTATION = gql`
    mutation updateDeviceTemplate($_id: String!, $input: DeviceTemplateUpdateInput!) {
        updateDeviceTemplate(_id: $_id, input: $input) {
            _id
            name
            ram
            flash
            system
            type
            topic
            imgUrl
            lang
            sensors
            supportedNetworks
        }
    }
`;

export const DELETE_DEVICE_TEMPLATE_MUTATION = gql`
    mutation deleteDeviceTemplate($_id: String!) {
        deleteDeviceTemplate(_id: $_id)
    }
`;
