import { useEffect, useMemo, useState } from 'react';
import { useQuery, useMutation, QueryResult } from '@apollo/client';

import { PageHeader, Button, Checkbox, Loader } from 'Components';
import {
    AddPlatformNetworks,
    AddPlatformNetworksVariables,
    GetPlatformNetworksQueryType,
    RemovePlatformNetworks,
    RemovePlatformNetworksVariables,

} from 'Types';
import { LORA, LTE, PLATFORM_NETWORKS } from 'Constants';
import { ADD_PLATFORM_NETWORKS_MUTATION, GET_PLATFORM_NETWORKS_QUERY, REMOVE_PLATFORM_NETWORKS_MUTATION } from 'Queries';
import { generateDeviceNetworkIcon, showToastError, showToastSuccess } from 'Utils';

import * as Styled from './styled';

export const Networks: React.FC = () => {
    const [selectedNetworks, setSelectedNetworks] = useState<string[]>([] as string[]);
    const [initialLoading, setInitialLoading] = useState<boolean>(true);

    const { data, loading }: QueryResult<GetPlatformNetworksQueryType> = useQuery(GET_PLATFORM_NETWORKS_QUERY);
    const networks = data?.getPlatformNetworks || [];

    const [removeNetworksMutation] = useMutation<
        RemovePlatformNetworks,
        RemovePlatformNetworksVariables
    >(REMOVE_PLATFORM_NETWORKS_MUTATION, {
        refetchQueries: [GET_PLATFORM_NETWORKS_QUERY],
    });

    const [addNetworksMutation] = useMutation<
        AddPlatformNetworks,
        AddPlatformNetworksVariables
    >(ADD_PLATFORM_NETWORKS_MUTATION, {
        refetchQueries: [GET_PLATFORM_NETWORKS_QUERY],
    });

    const saveChanges = async () => {
        const idsForRemoval = [];
        const networksToAdd = [];

        PLATFORM_NETWORKS.forEach((network) => {
            const isSelected = !!selectedNetworks.find((item) => item === network.name);
            const existingItem = networks.find((item) => item.name === network.name);

            if (isSelected && !existingItem) {
                networksToAdd.push(network);
            } else if (!isSelected && existingItem) {
                idsForRemoval.push(existingItem._id);
            }
        });

        try {
            let message = '';

            if (networksToAdd.length) {
                const { data: { addPlatformNetworks: addCount } } = await addNetworksMutation({ variables: { networks: networksToAdd } });
                message = message + ` Added: ${addCount} network${addCount === 1 ? '' : 's'}.`;
            }

            if (idsForRemoval.length) {
                const { data: { removePlatformNetworks: removalCount } } = await removeNetworksMutation(
                    { variables: { ids: idsForRemoval } }
                );
                message = message + ` Deleted: ${removalCount} network${removalCount === 1 ? '' : 's'}.`;
            }

            showToastSuccess(`Success.${message}`);
        } catch (error) {
            showToastError('Failed to save changes');
        }
    };

    const networksNames = useMemo(
        () => networks.map((network) => network.name),
        [networks]
    );

    useEffect(() => {
        if (!loading) {
            setInitialLoading(false);
            setSelectedNetworks(networksNames);
        }
    }, [loading]);

    const handleCheckbox = (name: string) => {
        const newSelection = [...selectedNetworks];
        const existingIndex = newSelection.findIndex((item) => item === name);
        if (existingIndex >= 0) {
            newSelection.splice(existingIndex, 1);
        } else {
            newSelection.push(name);
        }
        setSelectedNetworks(newSelection);
    };

    if (initialLoading) {
        return <Loader />;
    }

    let disabledBySelection = true;

    PLATFORM_NETWORKS.forEach((network) => {
        const foundInNetworks = networks.find((item) => item.name === network.name);
        const foundInSelection = selectedNetworks.find((item) => item === network.name);
        if ((foundInNetworks && !foundInSelection) || (!foundInNetworks && foundInSelection)) {
            disabledBySelection = false;
        }
    });

    const saveDisabled = !selectedNetworks.length || disabledBySelection;

    return (
        <>
            <Styled.Wrapper>
                <PageHeader
                    title="Networks"
                    description={[
                        'Enable and disable available networks to your platform',
                    ]}
                />
                <Styled.Networks>
                    {PLATFORM_NETWORKS.map((network) => (
                        <Styled.NetworkCheckbox key={network.name}>
                            <Styled.Network
                                key={network.name}
                                $isLTE={network.name === LTE}
                                $isLora={network.name === LORA}
                            >
                                {generateDeviceNetworkIcon(network.name)}
                            </Styled.Network>
                            <Checkbox
                                checked={selectedNetworks.includes(network.name)}
                                handleCheckbox={() => handleCheckbox(network.name)}
                            />
                        </Styled.NetworkCheckbox>
                    ))}
                </Styled.Networks>
                <Button onClick={saveChanges} disabled={saveDisabled}>
                    Save changes
                </Button>
            </Styled.Wrapper>
        </>
    );
};
