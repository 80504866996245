import { Fragment } from 'react';
import { Menu } from 'antd';

import { ReactComponent as Actions } from 'Assets/icons/actions.svg';

import { IDropdown } from './interfaces';
import * as Styled from './styled';

export const Dropdown: React.FC<IDropdown> = ({ actions, menuWidth, Icon, id, className }) => {
    const propagationStopperWithFunction = (event: React.SyntheticEvent, func: (id: string) => unknown) => {
        event.stopPropagation();
        func && func(id);
    };

    const propagationStopper = (event: React.SyntheticEvent) => {
        event.stopPropagation();
    };

    const menu = (
        <Menu style={{ width: `${menuWidth}px` }}>
            {actions.map((action, index) => (
                <Fragment key={action.title}>
                    <Styled.Menu
                        key={action.title}
                        onClick={({ domEvent }) => propagationStopperWithFunction(domEvent, action.executedFunction)}
                        disabled={Boolean(action.disabled)}
                    >
                        {action.title}
                    </Styled.Menu>
                    {index < actions.length - 1 && (
                        <Styled.Divider />
                    )}
                </Fragment>
            ))}
        </Menu>
    );

    return (
        <Styled.Dropdown
            overlay={menu}
            trigger={['click']}
            onClick={propagationStopper}
        >
            <Styled.Button icon={Icon} className={className} />
        </Styled.Dropdown>
    );
};

Dropdown.defaultProps = {
    menuWidth: 100,
    Icon: <Actions />,
    id: '',
    className: '',
};
