import styled, { ThemeProps } from 'styled-components';

import { Theme } from 'Types';
import { shadowSm } from 'Styles';

type NetworkType = ThemeProps<Theme> & ({
    $isLora: boolean;
    $isLTE: boolean;
});

export const Wrapper = styled.div`
    width: 100%;
`;

export const Networks = styled.div`
    display: flex;
    flex-direction: row;
    margin: 40px 0;
`;

export const Network = styled.div`
    font-size: 70px;
    color: ${({ theme }: ThemeProps<Theme>) => theme.colors.title};
    margin-right: 2.5rem;
    svg {
        width: ${({ $isLora, $isLTE }: NetworkType) => {
        if ($isLora) {
            return '1.6em';
        } else if ($isLTE) {
            return '1.2em';
        }
    }}
    }
`;

export const NetworkCheckbox = styled.div`
    display: flex;
    align-items: center;
    background: white;
    border: 1px solid ${({ theme }: ThemeProps<Theme>) => theme.colors.border};
    border-radius: 0.5rem;
    margin-right: 10px;
    padding: 2rem 0.5rem 0.5rem 3.5rem;

    ${shadowSm}

    span {
        margin-top: -20px;
    }
`;
