import { Notification } from 'Types';

import { initialValues } from './config';

export const getInitialValues = (notification: Notification): Notification => {
    if (!notification.systemNotificationId) {
        return initialValues;
    }
    return notification;
};
