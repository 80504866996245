import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { sessionContext } from 'Context';
import { ROUTES } from 'Constants';
import { SingleChildrenArgumentType } from 'Types';

import { Logo } from '../components';

import * as Styled from './styled';

export const AuthLayout: React.FC<SingleChildrenArgumentType> = ({ children }) => {
    const { session } = useContext(sessionContext);
    const navigate = useNavigate();

    if (session) {
        navigate(ROUTES.main);
    }

    return (
        <Styled.Wrapper>
            <Styled.Header>
                <Logo />
            </Styled.Header>
            <Styled.ContentWrapper>
                <Styled.Content>
                    {children}
                </Styled.Content>
            </Styled.ContentWrapper>
        </Styled.Wrapper>
    );
};

AuthLayout.defaultProps = {
    children: null,
};
