import styled, { ThemeProps } from 'styled-components';
import { Form as FormikForm } from 'formik';

import { Theme } from 'Types';

type NetworkType = ThemeProps<Theme> & ({
    $isLora: boolean;
    $isLTE: boolean;
});

export const Content = styled.div`
    margin-top: 30px;
`;

export const Title = styled.div`
    font-weight: bold;
    min-width: 100px;
`;

export const SettingsTitle = styled.div`
    font-weight: bold;
    font-size: 18px;
    min-width: 100px;
`;

export const FormLabel = styled.div`
    color: ${({ theme }: ThemeProps<Theme>) => theme.colors.title};
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 30px;
`;

export const Add = styled.div`
    display: flex;
    align-items: center;

    button {
        margin-right: 15px;
    }
`;

export const FieldWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-top: 20px;
`;

export const SettingsWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-top: 10px;
    justify-content: space-between;

    button {
        max-width: 50px;
        padding: 5px;

        svg {
            width: 15px;
            height: 15px;
        }

    }
`;

export const Networks = styled.div`
    display: flex;
    flex-direction: row;
    margin: 20px 0;
`;

export const NetworkCheckbox = styled.div`
    display: flex;
    align-items: center;
    border: 1px solid ${({ theme }: ThemeProps<Theme>) => theme.colors.border};
    margin-right: 10px;
    padding: 15px 0 0 20px;
    box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.2);

    span {
        margin-top: -20px;
    }
`;

export const Network = styled.div`
    font-size: 40px;
    color: ${({ theme }: ThemeProps<Theme>) => theme.colors.title};
    margin-right: 15px;

    svg {
        width: ${({ $isLora, $isLTE }: NetworkType) => {
        if ($isLora) {
            return '1.6em';
        } else if ($isLTE) {
            return '1.2em';
        }
    }};
    }
`;

export const ImageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-items: center;
    height: 100%;
    margin-top: 20px;
`;

export const UploaderContainer = styled.div`
    margin-top: -65px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-self: flex-end;
    button {
        margin-left: 1rem;
    }
`;

export const Image = styled.img`
    padding: 10px;
    max-width: 450px;
    max-height: 350px;
    border-radius: 15px;
    border: 1px dashed ${({ theme }: ThemeProps<Theme>) => theme.colors.active}
`;

export const ContentWrapper = styled.div`
    display: flex;
`;

export const Section = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 80px;
`;

export const AddibleField = styled.div`
    display: flex;
    margin-bottom: 10px
`;

export const AddibleFieldControls = styled.div`
    display: flex;
    margin-left: -5px;
    flex-shrink: 1;
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 120px;
    padding-bottom: 10px;

    button {
        text-transform: uppercase;
        font-weight: bold;
        margin-right: 10px;
    }

    & > div {
        margin-right: 15px;
    }
`;

export const Form = styled(FormikForm)`
    display: flex;
    flex-direction: column;
    margin-top: 10px;
`;
