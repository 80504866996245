import { useState, useEffect } from 'react';

import { emptyFunction } from 'Utils';

import { RangeDisplayItems } from '../rangeDisplayItems';
import { SearchInput } from '../inputs';
import { IRange } from '../rangeDisplayItems';

import { ITable, IChangeCursorProps } from './interfaces';
import { Pagination } from './pagination';
import * as Styled from './styled';

export const Table: React.FC<ITable> = ({
    columns,
    data,
    size,
    onRowClick,
    onSelect,
    searchOptions,
    pagination,
    onPaginationChange,
    rowKey,
    selectedRowKeys,
    maxBodyHeight,
    selectionType,
    isRangeShow,
    labelOfRange,
    initialRange,
    addClassToRow,
    wrappedTable,
}) => {
    const [range, setRange] = useState<IRange>({ type: '', value: `${initialRange}` });
    const [cursor, setCursor] = useState<IChangeCursorProps>(null);

    const rowSelection = onSelect
        ? {
            onChange: onSelect,
            selectedRowKeys,
            type: selectionType,
        } : null;

    const onRow = onRowClick ? (record: unknown) => ({ onClick: () => onRowClick(record) }) : undefined;

    useEffect(() => {
        if (cursor || +range.value !== initialRange) {
            onPaginationChange({
                cursors: { ...cursor }, items: +range.value,
            });
        }
    }, [cursor, range]);

    return (
        <>
            {searchOptions && (
                <Styled.SearchWrapper>
                    <SearchInput
                        placeholder={searchOptions.placeholder}
                        onSearch={searchOptions.onSearch}
                    />
                </Styled.SearchWrapper>
            )}
            <Styled.TableWrapper
                $clickableRows={!!onRowClick}
                $wrappedTable={wrappedTable}
                columns={columns}
                dataSource={data}
                pagination={false}
                size={size}
                rowSelection={rowSelection}
                onRow={onRow}
                rowKey={rowKey}
                scroll={{ y: maxBodyHeight }}
                rowClassName={addClassToRow}
            />
            <Styled.TableFooter>
                {pagination && (
                    <Pagination {...pagination} onChange={setCursor} />
                )}
                {isRangeShow && (
                    <RangeDisplayItems
                        title={labelOfRange}
                        handleRange={setRange}
                        selectedItem={+range.value}
                    />
                )}
            </Styled.TableFooter>
        </>
    );
};

Table.defaultProps = {
    data: [],
    size: 'small',
    onRowClick: null,
    onSelect: null,
    searchOptions: null,
    pagination: null,
    onPaginationChange: emptyFunction,
    rowKey: '_id',
    selectedRowKeys: undefined,
    maxBodyHeight: undefined,
    selectionType: 'checkbox',
    isRangeShow: false,
    labelOfRange: '',
    initialRange: 10,
    addClassToRow: null,
    wrappedTable: false,
};
