import styled, { css, ThemeProps } from 'styled-components';

import { Theme } from 'Types';

type ErrorStylesType = ThemeProps<Theme> & {
    $position: string;
};

type DisabledStylesType = ThemeProps<Theme> & {
    disabled: boolean;
};

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    position: relative;
`;

const errorRightStyles = css`
    margin-left: 10px;
`;

const errorBottomStyles = css`
    left: 0;
    bottom: -15px;
`;

export const Error = styled.span`
    position: absolute;
    margin-left: 0;
    transition: margin-left 0.4ms linear;
    transition: bottom 0.4s linear;
    color: ${({ theme }: ThemeProps<Theme>) => theme.colors.error};
    font-size: 12px;

    ${({ $position }: ErrorStylesType) => {
        switch ($position) {
            case 'right':
                return errorRightStyles;
            case 'bottom':
                return errorBottomStyles;
            default:
                return '';
        }
    }}
`;

export const Field = styled.textarea`
    width: 293px;
    min-height: 40px;
    border-radius: 5px;
    border: 1px solid ${({ theme }: DisabledStylesType) => theme.colors.border};
    padding: 9px 15px;
    color: ${({ theme }: DisabledStylesType) => theme.colors.text};
    resize: none;
    font-size: 14px;
    height: 1.4;

    ${({ disabled }: DisabledStylesType) => disabled && `
        border: transparent;
        cursor: not-allowed;
    `}
`;
