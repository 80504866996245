import { useState, useEffect, useMemo } from 'react';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useQuery, useMutation, QueryResult } from '@apollo/client';

import { PageHeader, Loader } from 'Components';
import {
    GET_PLATFORM_NETWORKS_QUERY,
    GET_DEVICE_AVAILABLE_TOPICS_QUERY,
    CREATE_DEVICE_TEMPLATE_MUTATION,
    GET_DEVICE_TEMPLATES_QUERY,
} from 'Queries';
import {
    DeviceTemplateTopic,
    GetPlatformNetworksQueryType,
    GetDeviceTemplateTopics,
    CreateDeviceTemplateMutationType,
    CreateDeviceTemplateMutationVariables,
} from 'Types';
import { showToastError, showToastSuccess } from 'Utils';

import { FormContent } from './formContent';
import { getInitialValues, validationSchema } from './config';
import { IValues } from './interfaces';
import * as Styled from './styled';

export const AddDeviceTemplate: React.FC = () => {
    const [initialLoading, setInitialLoading] = useState<boolean>(true);
    const navigate = useNavigate();

    const { data: networksData, loading }: QueryResult<GetPlatformNetworksQueryType> = useQuery(GET_PLATFORM_NETWORKS_QUERY);
    const networks = networksData?.getPlatformNetworks || [];

    const { data: topicsData, loading: topicsLoading }: QueryResult<GetDeviceTemplateTopics> = useQuery(
        GET_DEVICE_AVAILABLE_TOPICS_QUERY,
        {
            fetchPolicy: 'cache-and-network',
        }
    );
    const topics = topicsData?.getDeviceTemplateTopics || [] as DeviceTemplateTopic[];

    const [createDeviceTemplateMutation] = useMutation<
        CreateDeviceTemplateMutationType,
        CreateDeviceTemplateMutationVariables
    >(CREATE_DEVICE_TEMPLATE_MUTATION, {
        refetchQueries: [GET_DEVICE_TEMPLATES_QUERY],
        onCompleted: (data) => {
            if (data?.createDeviceTemplate) {
                showToastSuccess('Successfully created');
                navigate(-1);
            } else {
                showToastError('Could not create a new template');
            }
        },
    });

    useEffect(
        () => {
            if (!loading && !topicsLoading) {
                setInitialLoading(false);
            }
        },
        [loading, topicsLoading]
    );

    const topicsLabels = useMemo(
        () => topics.map((topic) => topic.label),
        [topics]
    );

    const navigateBack = () => navigate(-1);

    const onSubmit = async (values: IValues): Promise<void> => {
        const topic = topics.find((topicObject) => topicObject.label === values.topic).name;

        const supportedNetworks = values.supportedNetworks
            .filter((network) => network.check)
            .map((network) => network.key);

        if (!supportedNetworks.length) {
            showToastError('At least one network has to be chosen');
            return;
        }

        const sensors = values.sensors.filter((sensor) => !!sensor.trim());

        const input = { ...values, supportedNetworks, topic, sensors };
        createDeviceTemplateMutation({ variables: { input } });
    };

    if (initialLoading) {
        return <Loader />;
    }

    return (
        <>
            <PageHeader
                title="Add device template"
                description={[
                    'The changes you make, will be applied to main service to be displayed in section of adding a new device',
                ]}
            />
            <Styled.Content>
                <Formik
                    initialValues={getInitialValues(networks, topics)}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {(props) => (
                        <FormContent
                            {...props}
                            onClickBack={navigateBack}
                            topics={topicsLabels}
                            formMode="New"
                        />
                    )}
                </Formik>
            </Styled.Content>
        </>
    );
};
