import { useState, useMemo } from 'react';
import { useQuery, QueryResult } from '@apollo/client';

import { TotalMessagesQuery } from 'Types';
import { MESSAGES_TOTAL_QUERY } from 'Queries';
import { getSimplifiedNumber } from 'Utils';
import { ReactComponent as LineChartIcon } from 'Assets/icons/pre-line-graph.svg';
import { ReactComponent as PieChartIcon } from 'Assets/icons/pre-pie-chart.svg';
import { ReactComponent as NumbersIcon } from 'Assets/icons/chr-number.svg';
import { ReactComponent as MessagesIcon } from 'Assets/icons/chat.svg';

import { DataType } from '../types';
import { IWidget } from '../interfaces';
import * as Styled from '../styled';

import { MessagesLineChart, MessagesPieChart, MessagesTextWidget } from './components';

export const MessagesWidget: React.FC<IWidget> = () => {
    const [type, setType] = useState<DataType>('LineChart');
    const { data: messagesTotalData }: QueryResult<TotalMessagesQuery> = useQuery(MESSAGES_TOTAL_QUERY);
    const totalMessagesCount = messagesTotalData?.getTotalMessages ?? 0;

    const dataVisualisation = useMemo(() => {
        switch (type) {
            case 'LineChart':
                return <MessagesLineChart />;
            case 'PieChart':
                return <MessagesPieChart />;
            default:
            case 'Numbers':
                return <MessagesTextWidget />;
        }
    }, [type]);
    
    return (
        <Styled.Card>
            <Styled.Title>
                Messages
            </Styled.Title>
            <Styled.MainStat>
                <Styled.Total>
                    <Styled.MainLabel>
                        Total
                    </Styled.MainLabel>
                    <Styled.MainCount>
                        { getSimplifiedNumber(totalMessagesCount) }
                    </Styled.MainCount>
                </Styled.Total>
                <Styled.Icon>
                    <MessagesIcon />
                </Styled.Icon>
            </Styled.MainStat>
            <Styled.HDivider />
            <Styled.Details>
                <Styled.DataTypes>
                    <Styled.DataType
                        $active={type === 'LineChart'}
                        onClick={() => setType('LineChart')}
                    >
                        <LineChartIcon />
                    </Styled.DataType>
                    <Styled.DataType
                        $active={type === 'PieChart'}
                        onClick={() => setType('PieChart')}
                    >
                        <PieChartIcon />
                    </Styled.DataType>
                    <Styled.DataType
                        $active={type === 'Numbers'}
                        onClick={() => setType('Numbers')}
                    >
                        <NumbersIcon />
                    </Styled.DataType>
                </Styled.DataTypes>
                { dataVisualisation }    
            </Styled.Details>
        </Styled.Card>
    );
};

