import { Field } from 'formik';

import { TextInput, Button } from 'Components';

import { ITemplateForm } from './interfaces';
import * as Styled from './styled';

export const FormContent: React.FC<ITemplateForm> = ({ isValid, onClickBack, formMode }) => (
    <Styled.Form>
        <Styled.FormLabel>
            {`${formMode} Device Topic Template`}
        </Styled.FormLabel>
        <Styled.ContentWrapper>
            <Styled.FieldWrapper>
                <Styled.Title>
                    Label
                </Styled.Title>
                <Field
                    name="label"
                    type="text"
                    placeholder="Enter your topic topic label"
                    component={TextInput}
                    errorPosition="bottom"
                />
            </Styled.FieldWrapper>
            <Styled.FieldWrapper>
                <Styled.Title>
                    Tag
                </Styled.Title>
                <Field
                    name="name"
                    type="text"
                    placeholder="Enter your topic tag"
                    component={TextInput}
                    errorPosition="bottom"
                />
            </Styled.FieldWrapper>
            <Styled.ButtonsWrapper>
                <Button buttonType="transparent" onClick={onClickBack}>
                    Back
                </Button>
                <Button disabled={!isValid} type="submit">
                    Save
                </Button>
            </Styled.ButtonsWrapper>
        </Styled.ContentWrapper>
    </Styled.Form>
);
