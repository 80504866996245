import React from 'react';
import { ComposedChart as ReChart, Line, Area, XAxis, Tooltip, Legend, YAxis } from 'recharts';

import * as Styled from '../../styled';

import { ILineChart } from './interfaces';

const tooltipStyles = { borderRadius: '8px', border: '1px solid #F0F0F0', backgroundColor: '#FAFAFA', paddingBottom: '0.25rem' };

export const LineChart: React.FC<ILineChart> = ({ displayedData, lines, areas, label }) => {
    return (
        <Styled.ResponsiveContainer width="100%" height={150}>
            <ReChart data={displayedData} margin={{ left: 5, top: 5, right: 5, bottom: 5 }}>
                <defs>
                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#83d9e3" stopOpacity={0.8}/>
                        <stop offset="95%" stopColor="#83d9e3" stopOpacity={0}/>
                    </linearGradient>
                </defs>
                {!!areas?.length && areas.map((area, index) => (
                    <React.Fragment key={area.dataKey}>
                        <Area
                            type="monotone"
                            dot={false}
                            dataKey={area.dataKey}
                            fill="url(#colorUv)"
                            stroke={area.stroke}
                            strokeWidth={2}
                            yAxisId={index + 50}
                        />
                        <YAxis yAxisId={index+50} hide />
                    </React.Fragment>
                ))}
                {!!lines?.length && lines.map((line, index) => (
                    <React.Fragment key={line.dataKey}>
                        <Line type="monotone" dot={false} dataKey={line.dataKey} stroke={line.stroke} strokeWidth={2} yAxisId={index} />
                        <YAxis yAxisId={index} hide />
                    </React.Fragment>
                ))}
                <XAxis dataKey={label} interval="preserveStart" axisLine={{ stroke: 'red' }}/>
                <Tooltip contentStyle={tooltipStyles} />
                <Legend />
            </ReChart>
        </Styled.ResponsiveContainer>

    );
};
