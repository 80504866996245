export const LORA = 'lora';
export const WIFI = 'wifi';
export const LTE = 'lte';
export const ETHERNET = 'ethernet';
export const SIGFOX = 'sigfox';
export const BLUETOOTH = 'bluetooth';

export const PLATFORM_NETWORKS = [
    {
        name: WIFI,
        label: 'WiFi',
    },
    {
        name: LTE,
        label: 'LTE',
    },
    {
        name: LORA,
        label: 'LoRa',
    },
    {
        name: ETHERNET,
        label: 'Ethernet',
    },
    {
        name: SIGFOX,
        label: 'SigFox',
    },
];
