import { PropsWithChildren } from 'react';

import { IMenu } from './interfaces';
import * as Styled from './styled';

export const TableMenu: React.FC<PropsWithChildren<IMenu>> = ({ children, links }) => (
    <Styled.Wrapper>
        <Styled.List>
            {links &&
                links.map((link) => (
                    <Styled.Item
                        key={link.title}
                        to={link.route}
                    >
                        <div>{link.title}</div>
                    </Styled.Item>
                ))}
        </Styled.List>
        {children}
    </Styled.Wrapper>
);
