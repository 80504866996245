import { useMemo } from 'react';

import { IStepper } from './interfaces';
import * as Styled from './styled';

export const Stepper: React.FC<IStepper> = ({
    step,
    maxStep,
}) => {
    const width = useMemo(() => 100 - (((maxStep - step) * 100) / maxStep), [step, maxStep]);

    return (
        <Styled.Stepper>
            <Styled.Number>
                <span>0{step}</span>
                <span>/0{maxStep}</span>
            </Styled.Number>
            <Styled.Block>
                <Styled.Filled style={{ width: `${width}%` }} />
            </Styled.Block>
        </Styled.Stepper>
    );
};

Stepper.defaultProps = {
    step: 1,
    maxStep: 4,
};
