import { useQuery, QueryResult } from '@apollo/client';

import {
    SERVICE_STATISTIC_QUERY,
} from 'Queries';
import {
    ServiceStatistic,
    ServiceStatisticQuery,
} from 'Types';
import { ReactComponent as DownloadIcon } from 'Assets/icons/file_download.svg';
import { emptyFunction } from 'Utils';

import {
    DevicesWidget,
    UsersWidget,
    MessagesWidget,
    PymeshsWidget,
    ProjectsWidget,
    ReleasesWidget,
} from './widgets';
import * as Styled from './styled';

export const Home: React.FC = () => {
    
    const { data: serviceStatisticsData }: QueryResult<ServiceStatisticQuery> = useQuery(SERVICE_STATISTIC_QUERY);
    const serviceStatistics = serviceStatisticsData?.getServiceStatistic ?? {} as ServiceStatistic;

    return (
        <>
            <Styled.Header>
                <Styled.PageTitle>
                    Statistics
                </Styled.PageTitle>
                <Styled.Export
                    Icon={<DownloadIcon />}
                    onClick={emptyFunction}
                    isTextBold={true}
                >
                    Export
                </Styled.Export>
            </Styled.Header>
            <Styled.FirstRow>
                <DevicesWidget total={serviceStatistics.devicesCount} />
                <UsersWidget total={serviceStatistics.usersCount} />
                <MessagesWidget />
                <Styled.TwoCols>
                    <ProjectsWidget />
                    <ReleasesWidget />
                    <PymeshsWidget />
                </Styled.TwoCols>
            </Styled.FirstRow>
        </>
    );
};
