import { Field } from 'formik';
import { useEffect } from 'react';

import { emptyFunction } from 'Utils';

import { TextInput } from '../../inputs';

import { IDeleteModalForm } from './interfaces';
import * as Styled from './styled';

export const FormContent: React.FC<IDeleteModalForm> = ({ checkingValue, handleDisable, values }) => {
    useEffect(() => {
        if (values.username === checkingValue) {
            handleDisable(false);
        } else {
            handleDisable(true);
        }
    }, [values.username]);

    return (
        <Styled.Form>
            <Styled.FormLabel>
                Enter your username
            </Styled.FormLabel>
            <Field
                name="username"
                type="text"
                placeholder="Enter your username"
                component={TextInput}
            />
        </Styled.Form>
    );
};

FormContent.defaultProps = {
    checkingValue: '',
    handleDisable: emptyFunction,
};

