import * as Yup from 'yup';

import { PlatformNetwork, DeviceTemplate, DeviceTemplateTopic } from 'Types';
import { sortNetworks } from 'Utils';

import { IValues } from './interfaces';

export const validationSchema = Yup.object().shape({
    name: Yup.string().required('name is required.'),
    ram: Yup.number().typeError('value has to be a number').required('ram size can not be empty'),
    flash: Yup.number().typeError('value has to be a number').required('ram size can not be empty'),
    system: Yup.string().required('system type is required.'),
    type: Yup.string().required('device type is required.'),
    lang: Yup.string().required('programming language is required.'),
    sensors: Yup.array(Yup.string()),
    supportedNetworks: Yup.array(Yup.object().shape({
        key: Yup.string(),
        check: Yup.boolean(),
    })),
    imgUrl: Yup.string().required('image is required'),
    topic: Yup.string().required('topic is required.'),
});

export const getInitialValues = (
    networks: PlatformNetwork[],
    topics: DeviceTemplateTopic[],
    values: Readonly<DeviceTemplate> = null
): IValues => ({
    name: values?.name || '',
    ram: values?.ram || '',
    flash: values?.flash || '',
    system: values?.system || '',
    type: values?.type || '',
    lang: values?.lang || '',
    sensors: values?.sensors || [],
    supportedNetworks: sortNetworks(networks).map((network) => ({
        key: network.name,
        check: Boolean(values?.supportedNetworks.includes(network.name)),
    })) || [],
    imgUrl: values?.imgUrl || '',
    topic: topics.find((topic) => topic.name === values?.topic)?.label || topics[0].label,
});
