import { useState, useEffect, useMemo } from 'react';
import { Formik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery, useMutation, QueryResult } from '@apollo/client';

import { PageHeader, Loader } from 'Components';
import {
    GET_PLATFORM_NETWORKS_QUERY,
    GET_DEVICE_AVAILABLE_TOPICS_QUERY,
    GET_DEVICE_TEMPLATE_BY_ID,
    UPDATE_DEVICE_TEMPLATE_MUTATION,
    GET_DEVICE_TEMPLATES_QUERY,
} from 'Queries';
import {
    GetPlatformNetworksQueryType,
    DeviceTemplateTopic,
    GetDeviceTemplateTopics,
    GetDeviceTemplateByIdQuery,
    GetDeviceTemplateByIdVariables,
    UpdateDeviceTemplateMutation,
    UpdateDeviceTemplateVariables,
} from 'Types';
import { showToastError, showToastSuccess } from 'Utils';

import { FormContent } from './formContent';
import { getInitialValues, validationSchema } from './config';
import { IValues } from './interfaces';
import * as Styled from './styled';

export const EditDeviceTemplate: React.FC = () => {
    const [initialLoading, setInitialLoading] = useState<boolean>(true);
    const navigate = useNavigate();
    const { id: _id } = useParams();

    const { data: deviceData, loading: deviceLoading }: QueryResult<
        GetDeviceTemplateByIdQuery,
        GetDeviceTemplateByIdVariables
    > = useQuery(
        GET_DEVICE_TEMPLATE_BY_ID,
        {
            variables: { _id },
            fetchPolicy: 'cache-and-network',
        }
    );
    const deviceTemplate = deviceData?.getDeviceTemplateById;

    const { data: topicsData, loading: topicsLoading }: QueryResult<GetDeviceTemplateTopics> = useQuery(
        GET_DEVICE_AVAILABLE_TOPICS_QUERY,
        {
            fetchPolicy: 'cache-and-network',
        }
    );
    const topics = topicsData?.getDeviceTemplateTopics || [] as DeviceTemplateTopic[];

    const { data: networksData, loading: networksLoading }: QueryResult<GetPlatformNetworksQueryType> = useQuery(
        GET_PLATFORM_NETWORKS_QUERY
    );
    const networks = networksData?.getPlatformNetworks || [];

    const [updateDeviceTemplateMutation] = useMutation<
        UpdateDeviceTemplateMutation,
        UpdateDeviceTemplateVariables
    >(UPDATE_DEVICE_TEMPLATE_MUTATION, {
        refetchQueries: [GET_DEVICE_TEMPLATES_QUERY],
        onCompleted: (data) => {
            if (data?.updateDeviceTemplate) {
                showToastSuccess('Successfully updated');
                navigate(-1);
            } else {
                showToastError('Could not edit a new template');
            }
        },
    });

    useEffect(
        () => {
            if (!deviceLoading && !networksLoading && !topicsLoading) {
                setInitialLoading(false);
            }
        },
        [deviceLoading, networksLoading, topicsLoading]
    );

    const topicsLabels = useMemo(
        () => topics.map((topic) => topic.label),
        [topics]
    );

    const navigateBack = () => navigate(-1);

    const onSubmit = async (values: IValues): Promise<void> => {
        const topic = topics.find((topicObject) => topicObject.label === values.topic).name;

        const supportedNetworks = values.supportedNetworks
            .filter((network) => network.check)
            .map((network) => network.key);

        if (!supportedNetworks.length) {
            showToastError('At least one network has to be chosen');
            return;
        }

        const sensors = values.sensors.filter((sensor) => !!sensor.trim());

        const input = { ...values, supportedNetworks, topic, sensors };
        updateDeviceTemplateMutation({ variables: { _id, input } });
    };

    if (initialLoading) {
        return <Loader />;
    }

    return (
        <>
            <PageHeader
                title="Add device template"
                description={[
                    'The changes you make, will be applied to main service to be displayed in section of adding a new device',
                ]}
            />
            <Styled.Content>
                <Formik
                    initialValues={getInitialValues(networks, topics, deviceTemplate)}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {(props) => (
                        <FormContent
                            {...props}
                            onClickBack={navigateBack}
                            topics={topicsLabels}
                            formMode="Edit"
                        />
                    )}
                </Formik>
            </Styled.Content>
        </>
    );
};
