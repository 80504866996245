import { Field, FieldArray } from 'formik';

import { Button, TextInput } from 'Components';
import { ReactComponent as Plus } from 'Assets/icons/plus.svg';
import { ReactComponent as Trash } from 'Assets/icons/trash.svg';

import { ISensors } from './interfaces';
import * as Styled from './styled';

export const Sensors: React.FC<ISensors> = ({ sensors }) => (
    <FieldArray
        name="sensors"
        render={(arrayHelpers) => (
            <div>
                {sensors && !!sensors.length ? (
                    sensors.map((_sensor, index) => (
                        <Styled.Sensors key={index}>
                            <Field
                                name={`sensors.${index}`}
                                type="text"
                                placeholder="Enter your sensor name"
                                component={TextInput}
                            />
                            <Styled.SensorsControls>
                                <Button onClick={() => arrayHelpers.insert(index + 1, '')} >
                                    <Plus />
                                </Button>
                                <Button buttonType="transparent" onClick={() => arrayHelpers.remove(index)} >
                                    <Trash   />
                                </Button>
                            </Styled.SensorsControls>
                        </Styled.Sensors>
                    ))
                ) : (
                    <Styled.AddSensor>
                        <Button onClick={() => arrayHelpers.push('')}>
                            <Plus />
                        </Button>
                        <div>
                            Add sensor
                        </div>
                    </Styled.AddSensor>
                )}
            </div>
        )}
    />
);
