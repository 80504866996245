import { BarChart as ReBarChart, Bar, XAxis, YAxis, Cell, Tooltip } from 'recharts';

import { CountByType } from 'Types';

import * as Styled from '../../styled';

import { colors } from './config';

interface IBarChart {
    chartData: CountByType[];
    nameKey: string;
    dataKey: string;
}

export const BarChart: React.FC<IBarChart> = ({ chartData, nameKey, dataKey }) => {
    return (
        <Styled.ResponsiveContainer width="100%" height={180}>
            <ReBarChart data={chartData} margin={{ left: -40, top: 5, right: 0, bottom: -5 }}>    
                <XAxis dataKey={nameKey} />
                <YAxis />
                <Tooltip />
                <Bar
                    dataKey={dataKey}
                    fill="#8884d8"
                >
                    {
                        chartData.map((_entry, index) => <Cell fill={colors[index % colors.length]} key={index} />)
                    }
                </Bar>
            </ReBarChart>
        </Styled.ResponsiveContainer>
    );
};
