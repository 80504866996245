import { gql } from '@apollo/client';

export const GET_PLATFORM_NETWORKS_QUERY = gql`
    query getPlatformNetworks {
        getPlatformNetworks {
            _id
            name
            label
        }
    }
`;

export const REMOVE_PLATFORM_NETWORKS_MUTATION = gql`
    mutation removePlatformNetworks($ids: [String]) {
        removePlatformNetworks(ids: $ids)
    }
`;

export const ADD_PLATFORM_NETWORKS_MUTATION = gql`
    mutation addPlatformNetworks($networks: [PlatformNetworkInput]) {
        addPlatformNetworks(networks: $networks)
    }
`;
