import { useState, useMemo, useEffect } from 'react';
import { useQuery, QueryResult } from '@apollo/client';
import moment from 'moment';

import {
    DEVICES_DAILY_STATISTICS_QUERY,
} from 'Queries';
import {
    DailyDevicesStatsType,
    DailyStatsQueries,
    DailyStatsInputType,
} from 'Types';

import { LineChart } from '../../../components';
import * as Styled from '../../../styled';
import { TimeOptions, days } from '../../../config';

const lines = [
    {
        dataKey: 'growth',
        stroke: '#6E26F4',
    },
    {
        dataKey: 'active',
        stroke: '#1890FF',
    },
];

export const DevicesLineChart: React.FC = () => {
    const [timeOption, setTimeOption] = useState<TimeOptions>('Month');
    const [animationCount, setAnimationCount] = useState<number>(0);
    const { data: dailyDevicesStatsData }: QueryResult<
        DailyStatsQueries,
        DailyStatsInputType
    > = useQuery(DEVICES_DAILY_STATISTICS_QUERY, {
        variables: {
            days: days[timeOption],
        },
    });
    const dailyDevicesStats = dailyDevicesStatsData?.getDailyDevicesStats ?? [] as DailyDevicesStatsType[];
    useEffect(() => {
        if (dailyDevicesStats.length > 0) {
            setAnimationCount(animationCount + 1);
        }
    }, [dailyDevicesStats]);

    const chartData = useMemo(() => {
        return dailyDevicesStats.map((item) => ({
            ...item,
            date: moment(item.date).format('MMMM Do'),
        }));
    }, [animationCount]);

    return (
        <>
            <Styled.Options>
                {Object.keys(days).map((option) => (
                    <Styled.Option key={option}
                        $active={timeOption === option}
                        onClick={() => setTimeOption(option as TimeOptions)}
                    >
                        {option}
                    </Styled.Option>
                ))}
            </Styled.Options>
            <LineChart
                displayedData={chartData}
                lines={lines}
                label="date"
            />
        </>
    );
};
