import { useContext, useState, useEffect } from 'react';
import { ThemeContext } from 'styled-components';
import { useQuery, QueryResult } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

import { GetAdminSessionQueryType, Theme } from 'Types';
import { Loader } from 'Components';
import {  ROUTES } from 'Constants';
import { sessionContext } from 'Context';
import { GET_ADMIN_DATA_QUERY } from 'Queries';

export const useAuthenticate = () => {
    const navigate = useNavigate();

    const styledTheme = useContext<Theme>(ThemeContext);
    const globalContext = useContext(sessionContext);
    const { session, setSession } = globalContext;

    const [isLoading, setIsLoading] = useState(true);

    const { data }: QueryResult<GetAdminSessionQueryType> = useQuery(GET_ADMIN_DATA_QUERY, {
        fetchPolicy: 'network-only',
        onCompleted: () => {
            setIsLoading(false);
        },
        onError: () => {
            setSession(null);
            navigate(ROUTES.auth.login);
        },
    });

    const checkLogin = () => {
        if (!session && data?.getAdminSession) {
            setSession(data?.getAdminSession);
        }
    };

    useEffect(() => {
        checkLogin();
    }, [data]);

    const loader = <Loader title={`Connecting to ${styledTheme?.whiteLabelCompany}`} />;

    return {
        loader,
        isLoading,
    };
};
