import styled, { css, ThemeProps } from 'styled-components';
import { Link } from 'react-router-dom';

import { Theme } from 'Types';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-right: 20px;
`;

export const Top = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 214px;
    height: 180px;
    border: 1px solid ${({ theme }: ThemeProps<Theme>) => theme.colors.border};
    border-radius: 5px;
`;

export const Content = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100px;
    height: 100px;
    border-radius: 10px;
    background-color: ${({ theme }:ThemeProps<Theme>) => theme.colors.boxBackground};
    cursor: pointer;

    a {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    svg {
        font-size: 50px;
        color: ${({ theme }: ThemeProps<Theme>) => theme.colors.title}
    }
`;

export const NewButton = styled.div`
    position: absolute;
    top: 5px;
    right: 5px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: ${({ theme }: ThemeProps<Theme>) => theme.colors.active};
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        font-size: 12px;
        color: ${({ theme }: ThemeProps<Theme>) => theme.colors.activeText};
    }
`;

const linkStyles = css`
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 20px;
    word-break: break-all;
    line-height: 1.2;
    font-size: 16px;

    & div {
        margin-left: 10px;
    }

    & svg {
        color: ${({ theme }: ThemeProps<Theme>) => theme.colors.active};
        font-size: 30px;
    }
`;

export const modalOpenBtn = styled.div`
    ${linkStyles}
`;

export const RouterLink = styled(Link)`
    ${linkStyles}
`;

export const ExternalLink = styled.a`
    ${linkStyles}
`;

const iconStyles = css`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 10px;
`;

export const ButtonIcon = styled.button`
    ${iconStyles}
`;

export const DivIcon = styled.div`
    ${iconStyles}
`;

export const Title = styled.div`
    max-width: 180px;
    overflow-wrap: normal;
`;
