import styled from 'styled-components';

export const Range = styled.div`
    display: flex;
    align-items: center;

    select {
        width: 62px;
    }
`;

export const RangeTitle = styled.div`
    font-size: 14px;
    line-height: 17px;
    margin-right: 8px;
`;
