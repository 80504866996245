import { Stepper } from '../stepper';

import { IPageHeader } from './interfaces';
import * as Styled from './styled';

export const PageHeader: React.FC<IPageHeader> = ({
    title,
    description,
    step,
    maxStep,
    stepper,
    withIcon,
}) => (
    <div>
        <div>
            <Styled.Item>
                <Styled.Top>
                    <Styled.Title $withIcon={withIcon}>
                        {withIcon && (
                            <Styled.BlockWrapper>
                                <Styled.ColoredSpan />
                                <Styled.BackgroundSpan />
                            </Styled.BlockWrapper>
                        )}
                        <Styled.PageTitle>
                            {title}
                        </Styled.PageTitle>
                    </Styled.Title>
                    {stepper && <Stepper step={step} maxStep={maxStep} />}
                </Styled.Top>
                <Styled.Bottom>
                    <Styled.PageDescription>
                        {description}
                    </Styled.PageDescription>
                </Styled.Bottom>
            </Styled.Item>
        </div>
    </div>
);

PageHeader.defaultProps = {
    description: [],
    step: 1,
    maxStep: 4,
    stepper: false,
    withIcon: true,
};
