import { useQuery, QueryResult } from '@apollo/client';

import { DashboardCardStatsQueries, DashboardCardStats } from 'Types';
import { DASHBOARD_PYMESHS_STATS } from 'Queries';
import { ReactComponent as PymeshIcon } from 'Assets/icons/hub.svg';

import { Card } from '../components';

export const PymeshsWidget: React.FC = () => {
    const { data: dashboardPymeshsData }: QueryResult<DashboardCardStatsQueries> = useQuery(DASHBOARD_PYMESHS_STATS);
    const dashboardPymeshsStats = dashboardPymeshsData?.getDashboardPymeshsStats ?? {} as DashboardCardStats;

    return (
        <Card name="Pymesh" stats={dashboardPymeshsStats} icon={<PymeshIcon />} />
    );
};
