import { getSimplifiedNumber } from 'Utils';

import * as Styled from '../../styled';

import * as CardStyles from './styled';
import { ICard } from './interface';

export const Card: React.FC<ICard> = ({ name, stats, icon }) => (
    <CardStyles.Card>
        <Styled.Title>
            {name[0].toUpperCase() + name.slice(1)}
        </Styled.Title>
        <Styled.MainStat>
            <Styled.Total>
                <Styled.MainLabel>
                    Total
                </Styled.MainLabel>
                <Styled.MainCount>
                    {getSimplifiedNumber(stats.total)}
                </Styled.MainCount>
            </Styled.Total>
            <Styled.Icon>
                { icon }
            </Styled.Icon>
        </Styled.MainStat>
        <CardStyles.HDivider />
        <CardStyles.Details>
            <CardStyles.DetailsList>
                <CardStyles.DetailsItem>
                    <Styled.DetailsLabel>
                        New (24h)
                    </Styled.DetailsLabel>
                    <Styled.DetailsCount>
                        {getSimplifiedNumber(stats.yesterday)}
                    </Styled.DetailsCount>
                </CardStyles.DetailsItem>
                <CardStyles.DetailsItem>
                    <Styled.DetailsLabel>
                        New (30Days)
                    </Styled.DetailsLabel>
                    <Styled.DetailsCount>
                        {getSimplifiedNumber(stats.thirtyDays)}
                    </Styled.DetailsCount>
                </CardStyles.DetailsItem>
                <Styled.DetailsItem>
                    <Styled.DetailsLabel>
                        New (Start of month)
                    </Styled.DetailsLabel>
                    <Styled.DetailsCount>
                        {getSimplifiedNumber(stats.startOfMonth)}
                    </Styled.DetailsCount>
                </Styled.DetailsItem>
            </CardStyles.DetailsList>
        </CardStyles.Details>
    </CardStyles.Card>
);
