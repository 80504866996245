import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Menu as AntMenu } from 'antd';

export const Account = styled(NavLink)`
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 0 !important;
    svg {
        margin-left: 3px;
        font-size: 17px;
    }
    &:hover {
        color: ${({ theme }) => theme.colors.active} !important;
    }
`;

export const LogOut = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    transition: color 0.3s;
    padding: 0 !important;
    color: ${({ theme }) => theme.colors.error};

    svg {
        margin-left: 3px;
        font-size: 17px;
    }
`;

export const UserInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0;
`;

export const Owner = styled.div`
    display: flex;
    align-items: center;
    gap: 0.75rem;
    user-select: none;
    span {
        transition: color 0.3s;
    }
    svg {
        font-size: 24px;
        color: ${({ theme }) => theme.colors.title};
        transition: color 0.3s;
    }
`;

export const UserName = styled.span`
    font-size: 0.875rem;
    font-weight: 700;
`;

export const UserMail = styled.span`
    letter-spacing: 0.5px;
    font-size: 0.75rem;
    font-weight: 300;
    opacity: 0.5;
    margin-top: -5px;
`;

export const Menu = styled(AntMenu)`
    border-radius: 0.5rem;
`;

export const Divider = styled.li`
    position: relative;
    width: 80%;
    height: 1px;
    margin: 0.5rem auto;
    background-color: ${({ theme }) => theme.colors.border};
`;
