import styled, { ThemeProps } from 'styled-components';
import { Form as FormikForm } from 'formik';

import { Theme } from 'Types';

export const Content = styled.div`
    margin-top: 30px;
`;

export const Title = styled.div`
    font-weight: bold;
    min-width: 100px;
`;

export const FormLabel = styled.div`
    color: ${({ theme }: ThemeProps<Theme>) => theme.colors.title};
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 30px;
`;

export const FieldWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-top: 20px;
`;

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 50px;
    padding-bottom: 10px;

    button {
        text-transform: uppercase;
        font-weight: bold;
        margin-right: 10px;
    }

    & > div {
        margin-right: 15px;
    }
`;

export const Form = styled(FormikForm)`
    display: flex;
    flex-direction: column;
    margin-top: 10px;
`;
