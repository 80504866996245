import { ColumnType } from 'Types';

import { Edit } from '../styled';

type ActiveEnum = 'Active' | 'Inactive';

export const getColumns = (editHandler: (id: string) => void): ColumnType[] => {
    return [
        {
            title: 'Title',
            dataIndex: 'title',
            width: '30%',
        },
        {
            title: 'Content',
            dataIndex: 'content',
            width: '50%',
        },
        {
            title: 'Type',
            dataIndex: 'type',
            width: '12%',
        },
        {
            title: 'Active',
            dataIndex: 'active',
            width: '8%',
            render: (data) => {
                const active = data as ActiveEnum;
                return active ? 'Active' : 'Inactive';
            },
        },
        {
            title: '',
            dataIndex: 'systemNotificationId',
            width: '25px',
            render: (data) => {
                const id = data as string;
                return (
                    <Edit
                        onClick={() => editHandler(id)}
                    />
                );
            },
        },
    ];
};
