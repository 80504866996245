import styled, { ThemeProps } from 'styled-components';
import {
    Button as AntButton,
    Menu as AntMenu,
    Dropdown as AntDropdown,
} from 'antd';

import { Theme } from 'Types';

type DropdownType = ThemeProps<Theme> & ({
    onClick: (event: React.SyntheticEvent) => void;
});

export const Button = styled(AntButton)`
    border: none;
    outline: none;
    border-radius: 50%;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-growth: -1;

    &:hover,
    &:active {
        border-radius: 50%;
        background-color: transparent;
    }

    svg {
        font-size: 20px;
        color: ${({ theme }: ThemeProps<Theme>) => theme.colors.text};
    }
`;

export const Dropdown = styled(AntDropdown)`
    border: none;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        font-size: 20px;
        line-height: 0;
        color: ${({ theme }: DropdownType) => theme.colors.text};
    }
`;

export const Menu = styled(AntMenu.Item)`
    width: 100%;
`;

export const Divider = styled(AntMenu.Divider)`
    margin: 0;
`;
