import { useState, useMemo } from 'react';

import { getSimplifiedNumber } from 'Utils';
import { ReactComponent as LineChartIcon } from 'Assets/icons/pre-line-graph.svg';
import { ReactComponent as PieChartIcon } from 'Assets/icons/pre-pie-chart.svg';
import { ReactComponent as NumbersIcon } from 'Assets/icons/chr-number.svg';
import { ReactComponent as UsersIcon } from 'Assets/icons/users.svg';

import { DataType } from '../types';
import * as Styled from '../styled';
import { IWidget } from '../interfaces';

import { UsersLineChart, UsersPieChart, UsersTextWidget } from './components';

export const UsersWidget: React.FC<IWidget> = ({ total }) => {
    const [type, setType] = useState<DataType>('LineChart');
    const dataVisualisation = useMemo(() => {
        switch (type) {
            case 'LineChart':
                return <UsersLineChart />;
            case 'PieChart':
                return <UsersPieChart />;
            default:
            case 'Numbers':
                return <UsersTextWidget />;
        }
    }, [type]);
    
    return (
        <Styled.Card>
            <Styled.Title>
                Users
            </Styled.Title>
            <Styled.MainStat>
                <Styled.Total>
                    <Styled.MainLabel>
                        Total
                    </Styled.MainLabel>
                    <Styled.MainCount>
                        { getSimplifiedNumber(total) }
                    </Styled.MainCount>
                </Styled.Total>
                <Styled.Icon>
                    <UsersIcon />
                </Styled.Icon>
            </Styled.MainStat>
            <Styled.HDivider />
            <Styled.Details>
                <Styled.DataTypes>
                    <Styled.DataType
                        $active={type === 'LineChart'}
                        onClick={() => setType('LineChart')}
                    >
                        <LineChartIcon />
                    </Styled.DataType>
                    <Styled.DataType
                        $active={type === 'PieChart'}
                        onClick={() => setType('PieChart')}
                    >
                        <PieChartIcon />
                    </Styled.DataType>
                    <Styled.DataType
                        $active={type === 'Numbers'}
                        onClick={() => setType('Numbers')}
                    >
                        <NumbersIcon />
                    </Styled.DataType>
                </Styled.DataTypes>
                { dataVisualisation }    
            </Styled.Details>
        </Styled.Card>
    );
};

