import { ROUTES } from 'Constants';
import {  PageHeader, TableMenu } from 'Components';
import { Outlet } from 'react-router-dom';

import * as Styled from './styled';

export const Devices: React.FC = () => {
    const getLinks = () => [
        { title: 'Device Templates', route: ROUTES.devices.deviceTemplates.main },
        { title: 'Shields Templates', route: ROUTES.devices.shieldTemplates.main },
        { title: 'Device Topic Templates', route: ROUTES.devices.deviceTopicTemplates.main },
    ];

    return (
        <>
            <PageHeader
                title="Templates"
                description={[
                    'Add, Edit and Delete device and shields template to be displayed in service',
                ]}
            />
            <Styled.Wrapper>
                <TableMenu links={getLinks()}>
                    <Outlet/>
                </TableMenu>
            </Styled.Wrapper>
        </>
    );
};
