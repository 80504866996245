import {
    ApolloClient,
    ApolloLink,
    HttpLink,
    InMemoryCache,
    InMemoryCacheConfig,
    NormalizedCacheObject,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';

import { Session } from 'Types';

import introspectionQueryResultData from '../fragmentTypes.json';

let client: ApolloClient<NormalizedCacheObject>;
const { REACT_APP_API_URL } = process.env;

function createClient(setSession: React.Dispatch<React.SetStateAction<Session>>): ApolloClient<NormalizedCacheObject> {
    const options = {
        uri: `${REACT_APP_API_URL}/system-admin/graphql`,
        credentials: 'include',
    };

    // Create an http link:
    let httpLink: ApolloLink = new HttpLink(options);

    const errorLink = onError(
        ({ operation, response, graphQLErrors }) => {
            console.error(operation, response);
            if (graphQLErrors) {
                graphQLErrors.map(({ message, locations, path, extensions }) => {
                    if (extensions.code === 'UNAUTHENTICATED') {
                        setSession(null);
                        window.location.href = '/login';
                    } else {
                        return console.error(`[GraphQL error]: Message: ${message},
                        Location: ${locations}, Path: ${path}`);
                    }
                });
            }
        }
    );

    httpLink = errorLink.concat(httpLink);

    return new ApolloClient({
        link: httpLink,
        cache: new InMemoryCache({
            introspectionQueryResultData,
        } as InMemoryCacheConfig),
    });
}

export function getClient(setSession: React.Dispatch<React.SetStateAction<Session>>): ApolloClient<NormalizedCacheObject> {
    if (client === undefined) {
        client = createClient(setSession);
    }

    return client;
}
