import { CUSTOM_PAGINATION } from 'Constants';

import { Select } from '../select';

import { IRangeDisplayItems } from './interfaces';
import * as Styled from './styled';

export const RangeDisplayItems: React.FC<IRangeDisplayItems> = ({
    title,
    handleRange,
    selectedItem,
    limits,
}) => (
    <Styled.Range>
        <Styled.RangeTitle>{title}</Styled.RangeTitle>
        <Select
            custom
            values={limits}
            selectedItem={selectedItem}
            handleSelect={handleRange}
        />
    </Styled.Range>
);

RangeDisplayItems.defaultProps = {
    selectedItem: 10,
    limits: CUSTOM_PAGINATION,
};
