import { useContext } from 'react';
import { ThemeContext } from 'styled-components';

import { Theme } from 'Types';

import * as Styled from './styled';

export const Logo: React.FC = () => {
    const themeContext: Theme = useContext(ThemeContext);

    return (
        <Styled.Logo data-test="Logo__container">
            <Styled.LogoImage src={themeContext?.logoUrl} alt="logo" />
        </Styled.Logo>
    );
};
