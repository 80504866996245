import * as Yup from 'yup';

import { IValues } from './interfaces';

export const validationSchema = Yup.object().shape({
    login: Yup.string().email('Invalid format. Should be valid email').required('Login is required.'),
    password: Yup.string().required('Password can not be empty.'),
});

export const initialValues: IValues = {
    login: '',
    password: '',
};
