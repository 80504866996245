import { useQuery } from '@apollo/client';
import { useState } from 'react';

import { PageHeader, Loader, Modal } from 'Components';
import { GET_ALL_SYSTEM_NOTIFICATIONS } from 'Queries';
import { GetAllSystemNotificationsQueryType, Notification } from 'Types';
import { ReactComponent as AddIcon } from 'Assets/icons/write.svg';

import { NotificationsTable } from './notificationsTable';
import { NotificationsModal } from './notificationsModal';
import * as Styled from './styled';

export const Notifications: React.FC = () => {
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
    const [selectedNotification, setSelectedNotification] = useState<Notification>({} as Notification);

    const { data, loading } = useQuery<GetAllSystemNotificationsQueryType>(GET_ALL_SYSTEM_NOTIFICATIONS);
    const notifications = data?.getAllSystemNotifications ?? [];

    const editNotificationHandler = (id: string): void => {
        const notification = notifications.find((notification) => notification.systemNotificationId === id);
        setSelectedNotification(notification);
        setModalIsOpen(true);
    };

    const createNotificationHandler = (): void => {
        setSelectedNotification({} as Notification);
        setModalIsOpen(true);
    };

    if (loading) {
        return <Loader />;
    }

    return (
        <Styled.Wrapper>
            <PageHeader
                title="Notifications"
                description={[
                    'Display system messages to users about the platform',
                ]}
            />
            <Styled.TableWrapper>
                <Styled.IconButton
                    Icon={<AddIcon />}
                    buttonType="transparent"
                    onClick={createNotificationHandler}
                >
                    New Notification
                </Styled.IconButton>
                <NotificationsTable
                    data={notifications}
                    editNotificationHandler={editNotificationHandler}
                />
            </Styled.TableWrapper>
            <Modal
                handleClose={() => setModalIsOpen(false)}
                isOpened={modalIsOpen}
            >
                <NotificationsModal
                    notification={selectedNotification}
                    handleClose={() => setModalIsOpen(false)}
                />
            </Modal>
        </Styled.Wrapper>
    );
};
