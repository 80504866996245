import { useState, useEffect } from 'react';
import { useQuery, QueryResult, useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as Plus } from 'Assets/icons/add_circle.svg';
import { Loader, Table, Modal, DeleteModal } from 'Components';
import { DeleteShieldTemplate, DeleteShieldTemplateVariables, GetShieldTemplates, ShieldTemplate } from 'Types';
import { GET_SHIELD_TEMPLATES_QUERY, DELETE_SHIELD_TEMPLATE_MUTATION } from 'Queries';
import { ROUTES } from 'Constants';
import { showToastError, showToastSuccess } from 'Utils';

import * as Styled from '../generalStyled';

import { getColumns } from './columns';

export const ShieldTemplates: React.FC = () => {
    const navigate = useNavigate();

    const [isOpenedModal, setIsOpenedModal] = useState<boolean>(false);
    const [initialLoading, setInitialLoading] = useState<boolean>(true);
    const [deletionId, setDeletionId] = useState<string>(null as string);

    const { data, loading }: QueryResult<GetShieldTemplates> = useQuery(GET_SHIELD_TEMPLATES_QUERY, {
        fetchPolicy: 'cache-and-network',
    });
    const shieldTemplates = data?.getShieldTemplates || [] as ShieldTemplate[];

    const [deleteShieldTemplateMutation] = useMutation<
        DeleteShieldTemplate,
        DeleteShieldTemplateVariables
    >(DELETE_SHIELD_TEMPLATE_MUTATION, {
        refetchQueries: [GET_SHIELD_TEMPLATES_QUERY],
        onCompleted: () => {
            showToastSuccess('Successfully deleted');
            handleModalClose();
        },
        onError: () => {
            showToastError('Deletion has failed');
        },

    });

    useEffect(
        () => {
            if(!loading) {
                setInitialLoading(false);
            }
        }, [loading]
    );

    const handleDelete = () => deleteShieldTemplateMutation({ variables: { _id: deletionId } });

    const handleModalClose = () => {
        setIsOpenedModal(false);
        setDeletionId(null);
    };

    const onAddButtonClick = () => {
        navigate(ROUTES.devices.shieldTemplates.add);
    };

    const editShieldTemplate = (id: string) => {
        navigate(`${id}/${ROUTES.devices.shieldTemplates.edit}`);
    };

    const deleteShieldTemplate = (id: string) => {
        setDeletionId(id);
        setIsOpenedModal(true);
    };

    if (initialLoading) {
        return <Loader />;
    }

    const columns = getColumns(editShieldTemplate, deleteShieldTemplate);

    return (
        <>
            <Styled.Wrapper>
                <Styled.AddButton onClick={onAddButtonClick} buttonType="transparent">
                    <Plus />
                    Add template
                </Styled.AddButton>
                <Table
                    columns={columns}
                    data={shieldTemplates}
                    wrappedTable={true}
                />
            </Styled.Wrapper>
            <Modal isOpened={isOpenedModal} handleClose={handleModalClose}>
                <DeleteModal
                    title="Shield template deletion"
                    description="Are you sure, you want to delete this template?"
                    cancelAction={handleModalClose}
                    deleteAction={handleDelete}
                />
            </Modal>
        </>

    );
};
