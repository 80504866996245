import { PieChart as RePieChart, Pie, Cell } from 'recharts';

import { getSimplifiedNumber } from 'Utils';
import { CountByType } from 'Types';

import * as Styled from '../../styled';

import { colors } from './config';

interface IPieChart {
    chartData: CountByType[];
    nameKey: string;
    dataKey: string;
}

export const PieChart: React.FC<IPieChart> = ({ chartData, nameKey, dataKey }) => {
    const renderCustomLabel = ({
        cx,
        cy,
        midAngle,
        innerRadius,
        outerRadius,
        value,
        index,
    }) => {
        const RADIAN = Math.PI / 180;
        const radius = 25 + innerRadius + (outerRadius - innerRadius);
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);
    
        return (
            <text
                x={x}
                y={y}
                fill={colors[index % colors.length]}
                textAnchor={x > cx ? 'start' : 'end'}
                dominantBaseline="central"
            >
                {chartData[index][nameKey]} ({getSimplifiedNumber(value)})
            </text>
        );
    };

    return (
        <Styled.ResponsiveContainer width="100%" height={180}>
            <RePieChart>
                <Pie
                    data={chartData}
                    dataKey={dataKey}
                    nameKey={nameKey}
                    cx="50%"
                    cy="50%"
                    innerRadius={25}
                    outerRadius={60}
                    legendType="circle"
                    animationDuration={750}
                    animationBegin={0}
                    label={renderCustomLabel}
                >
                    {
                        chartData.map((_entry, index) => <Cell fill={colors[index % colors.length]} key={index} />)
                    }
                </Pie>
            </RePieChart>
        </Styled.ResponsiveContainer>
    );
};
