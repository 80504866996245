import { FormikProps, FieldInputProps } from 'formik';

import { ITextArea } from './interfaces';

import * as Styled from './styled';

export const TextArea: React.FC<ITextArea> = ({
    disabled,
    field,
    form: { errors, touched },
    placeholder,
    errorPosition,
    rows,
}) => (
    <Styled.Wrapper>
        <Styled.Field
            placeholder={placeholder}
            value={field.value}
            name={field.name}
            onChange={field.onChange}
            onBlur={field.onBlur}
            disabled={disabled}
            rows={rows}
        />
        {!disabled && touched[field.name] && (
            <Styled.Error $position={errorPosition}>
                {errors[field.name]}
            </Styled.Error>
        )}
    </Styled.Wrapper>
);

TextArea.defaultProps = {
    disabled: false,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    form: {} as FormikProps<any>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    field: {} as FieldInputProps<any>,
    placeholder: '',
    errorPosition: 'right',
    rows: 1,
};
