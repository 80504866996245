import React from 'react';
import moment from 'moment';

import { ReactComponent as Home } from 'Assets/icons/home.svg';
import { ReactComponent as Devices } from 'Assets/icons/devices.svg';
import { ReactComponent as Network } from 'Assets/icons/networks.svg';
import { ReactComponent as LTE } from 'Assets/deviceNetworks/lte.svg';
import { ReactComponent as WiFi } from 'Assets/deviceNetworks/wifi.svg';
import { ReactComponent as LoRa } from 'Assets/deviceNetworks/lora.svg';
import { ReactComponent as Bluetooth } from 'Assets/deviceNetworks/ble.svg';
import { ReactComponent as Ethernet } from 'Assets/deviceNetworks/ethernet.svg';
import { ReactComponent as Sigfox } from 'Assets/deviceNetworks/sigfox.svg';
import { ReactComponent as Bell } from 'Assets/icons/bell.svg';

import {
    BLUETOOTH,
    LORA,
    WIFI,
    ETHERNET,
    LTE as LTE_NETWORK,
    SIGFOX,
    PLATFORM_NETWORKS,
} from 'Constants';
import { MomentDiff, PlatformNetwork } from 'Types';

const TIME_IN_MINUTES = {
    minute: 1,
    hour: 60,
    day: moment.duration(1, 'days').asMinutes(),
    month: moment.duration(1, 'months').asMinutes(),
    year: moment.duration(1, 'years').asMinutes(),
};

export function emptyFunction() {
    // do nothing
}

export const hexToRgb = (hex: string): string => hex
    .replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (_, r, g, b) => `#${r}${r}${g}${g}${b}${b}`)
    .substring(1)
    .match(/.{2}/g)
    .map((item) => parseInt(item, 16))
    .join(',');

export const generateIcon: React.FC<string> = (title) => {
    switch (title) {
        case 'home':
            return <Home />;
        case 'device':
            return <Devices />;
        case 'network':
            return <Network />;
        case 'bell':
            return <Bell />;
        default:
            return null;
    }
};

export const sortNetworks = (networks: PlatformNetwork[]) => {
    const platformNetworksArray = [...PLATFORM_NETWORKS];
    return platformNetworksArray.filter((item) => networks.find((network) => network.name === item.name));
};

export const generateDeviceNetworkIcon = (title: string) => {
    const copyTitle = title.includes('lora') ? LORA : title;
    switch (copyTitle) {
        case WIFI:
            return <WiFi key={title} />;
        case LTE_NETWORK:
            return <LTE key={title} />;
        case LORA:
            return <LoRa key={title} />;
        case ETHERNET:
            return <Ethernet key={title} />;
        case BLUETOOTH:
            return <Bluetooth key={title} />;
        case SIGFOX:
            return <Sigfox key={title} />;
        default:
            return null;
    }
};

export const fileToBase64 = (file: File): Promise<string | ArrayBuffer> => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
});

export const getTimeDiff = (date: string | number, errorText: Readonly<string> = null) => {
    const transformedDate = new Date(date);
    const isValidDate = date && (transformedDate.toString() !== 'Invalid Date');

    if (!isValidDate) {
        return errorText;
    }

    const { minute, hour, day, month, year } = TIME_IN_MINUTES;
    const momentToCompare = moment(transformedDate);
    const diff = moment().diff(momentToCompare, 'minutes');

    let timeStep: MomentDiff;
    switch (true) {
        case (diff < minute):
            timeStep = 'seconds';
            break;
        case (diff < hour):
            timeStep = 'minutes';
            break;
        case (diff < day):
            timeStep = 'hours';
            break;
        case (diff < month):
            timeStep = 'days';
            break;
        case (diff < year):
            timeStep = 'months';
            break;
        default:
            timeStep = 'years';
    }

    const timeDiffText = `${moment().diff(moment(transformedDate), timeStep)} ${timeStep} ago`;
    return timeDiffText;
};

export const roundToMaxTwoDigits = (number: number): number => {
    return Math.round(number * 100) / 100;
};

export const getSimplifiedNumber = (number: number): string | number => {
    let simplified: number;
    switch (true) {
        case (number >= 1000 && number < 1000000):
            simplified = number / 1000;
            return `${roundToMaxTwoDigits(simplified)}k`;
        case (number >= 1000000 && number < 1000000000):
            simplified = number / 1000000;
            return `${roundToMaxTwoDigits(simplified)}m`;
        case (number >= 1000000000):
            simplified = number / 1000000000;
            return `${roundToMaxTwoDigits(simplified)}b`;
        default:
            return number;
    }
};

export const getSimplifiedByteNumber = (number: number): string | number => {
    let simplified: number;
    switch (true) {
        case (number >= 1000 && number < 1000000):
            simplified = number / 1000;
            return `${roundToMaxTwoDigits(simplified)} KB`;
        case (number >= 1000000 && number < 1000000000):
            simplified = number / 1000000;
            return `${roundToMaxTwoDigits(simplified)} MB`;
        case (number >= 1000000000):
            simplified = number / 1000000000;
            return `${roundToMaxTwoDigits(simplified)} GB`;
        default:
            return `${number} Bytes`;
    }
}; 
