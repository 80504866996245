import React, { useState, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { ApolloProvider } from '@apollo/client';

import { Routes } from 'Routes';
import { Notification } from 'Components';
import { sessionContext } from 'Context';
import { Session, Theme } from 'Types';
import { getClient } from 'Queries';
import { GlobalStyles } from 'Styles';

import 'antd/dist/antd.min.css';

const { REACT_APP_STATIC_URL } = process.env;

export const App: React.FC = () => {
    const [session, setSession] = useState<Session>(null as Session);
    const [theme, setTheme] = useState<Theme>(null as Theme);

    const { Provider } = sessionContext;

    const client = getClient(setSession);

    const downloadTheme = async (): Promise<void> => {
        try {
            const response = await fetch(`${REACT_APP_STATIC_URL}/theme.json`);
            const themeResponse = await response.json();

            setTheme(themeResponse);
        } catch (error) {
            console.log('problems with loading theme, please check theme.json file');
        }
    };

    useEffect(() => {
        downloadTheme();
    }, []);

    if (!theme) {
        return null;
    }

    return (
        <Provider value = {{ session, client, setSession }}>
            <ThemeProvider theme={theme}>
                <GlobalStyles />
                <ApolloProvider client={client}>
                    <Notification />
                    <BrowserRouter>
                        <Routes />
                    </BrowserRouter>
                </ApolloProvider>
            </ThemeProvider>
        </Provider>
    );
};
