import React, { useState } from 'react';

import { ReactComponent as Search } from 'Assets/icons/search.svg';
import { emptyFunction } from 'Utils';

import { ISearchInput } from './interfaces';
import * as Styled from './styled';

export const SearchInput: React.FC<ISearchInput> = ({ placeholder, onSearch }) => {
    const [searchString, setSearchString] = useState<string>('');

    const onChange = (e: React.SyntheticEvent) => {
        const { value } = e.target as HTMLInputElement;
        setSearchString(value);
        onSearch(value);
    };

    const onIconClick = () => {
        onSearch(searchString);
    };

    return (
        <Styled.Wrapper>
            <Styled.Field
                type="text"
                value={searchString}
                placeholder={placeholder}
                onChange={onChange}
            />
            <Styled.Icon
                onClick={onIconClick}
                role="presentation"
            >
                <Search />
            </Styled.Icon>
        </Styled.Wrapper>
    );
};

SearchInput.defaultProps = {
    placeholder: '',
    onSearch: emptyFunction,
};
