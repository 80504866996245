import { useState, useEffect } from 'react';
import { useQuery, QueryResult, useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as Plus } from 'Assets/icons/add_circle.svg';
import { Loader, Table, Modal, DeleteModal } from 'Components';
import { DeleteDeviceTemplateTopic, DeleteDeviceTemplateTopicVariables, DeviceTemplateTopic, GetDeviceTemplateTopics } from 'Types';
import { GET_DEVICE_AVAILABLE_TOPICS_QUERY, DELETE_DEVICE_TOPIC_TEMPLATE_MUTATION } from 'Queries';
import { ROUTES } from 'Constants';
import { showToastError, showToastSuccess } from 'Utils';

import * as GeneralStyled from '../generalStyled';

import { getColumns } from './columns';

export const DeviceTopicTemplates: React.FC = () => {
    const navigate = useNavigate();

    const [isOpenedModal, setIsOpenedModal] = useState<boolean>(false);
    const [initialLoading, setInitialLoading] = useState<boolean>(true);
    const [deletionId, setDeletionId] = useState<string>(null as string);

    const { data, loading }: QueryResult<GetDeviceTemplateTopics> = useQuery(GET_DEVICE_AVAILABLE_TOPICS_QUERY, {
        fetchPolicy: 'cache-and-network',
    });
    const deviceTopicTemplates = data?.getDeviceTemplateTopics || [] as DeviceTemplateTopic[];

    const [deleteDeviceTopicTemplateMutation] = useMutation<
        DeleteDeviceTemplateTopic,
        DeleteDeviceTemplateTopicVariables
    >(DELETE_DEVICE_TOPIC_TEMPLATE_MUTATION, {
        refetchQueries: [GET_DEVICE_AVAILABLE_TOPICS_QUERY],
        onCompleted: () => {
            showToastSuccess('Successfully deleted');
            handleModalClose();
        },
        onError: () => {
            showToastError('Deletion has failed');
        },

    });

    useEffect(
        () => {
            if(!loading) {
                setInitialLoading(false);
            }
        }, [loading]
    );

    const handleDelete = () => deleteDeviceTopicTemplateMutation({ variables: { _id: deletionId } });

    const handleModalClose = () => {
        setIsOpenedModal(false);
        setDeletionId(null);
    };

    const onAddButtonClick = () => {
        navigate(ROUTES.devices.deviceTopicTemplates.add);
    };

    const editDeviceTopicTemplate = (id: string) => {
        navigate(`${id}/${ROUTES.devices.deviceTopicTemplates.edit}`);
    };

    const deleteDeviceTopicTemplate = (id: string) => {
        setDeletionId(id);
        setIsOpenedModal(true);
    };

    if (initialLoading) {
        return <Loader />;
    }

    const columns = getColumns(editDeviceTopicTemplate, deleteDeviceTopicTemplate);

    return (
        <>
            <GeneralStyled.Wrapper>
                <GeneralStyled.AddButton onClick={onAddButtonClick} buttonType="transparent">
                    <Plus />
                    Add template
                </GeneralStyled.AddButton>
                <Table
                    columns={columns}
                    data={deviceTopicTemplates}
                    wrappedTable={true}
                />
            </GeneralStyled.Wrapper>
            <Modal isOpened={isOpenedModal} handleClose={handleModalClose}>
                <DeleteModal
                    title="Device topic template deletion"
                    description="Are you sure, you want to delete this template?"
                    cancelAction={handleModalClose}
                    deleteAction={handleDelete}
                />
            </Modal>
        </>

    );
};
