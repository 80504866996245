import styled from 'styled-components';

import { IconButton } from '../buttons';

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const Button = styled(IconButton)`
    margin-right: 30px;
`;

export const Label = styled.div`
    font-size: 14px;
`;

export const FileInput = styled.input`
    display: none;
`;
