import React from 'react';

import { ILoader } from './interfaces';
import * as Styled from './styled';

export const Loader: React.FC<ILoader> = ({
    title = '',
    overlay = false,
    size = 100,
}) => (
    <Styled.Wrapper $overlay={overlay}>
        <div>
            <Styled.Title>
                {title}
            </Styled.Title>
            <Styled.Spinner $size={size} />
        </div>
    </Styled.Wrapper>
);
