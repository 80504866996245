import { useMemo, useState, useEffect } from 'react';
import { useQuery, QueryResult, useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as Plus } from 'Assets/icons/add_circle.svg';
import { Loader, Table, Modal, DeleteModal } from 'Components';
import {
    DeviceTemplate,
    DeviceTemplateTopic,
    GetDeviceTemplateQueryType,
    DeleteDeviceTemplateMutation,
    DeleteDeviceTemplateMutationVariables,
} from 'Types';
import { DELETE_DEVICE_TEMPLATE_MUTATION, GET_DEVICE_TEMPLATES_QUERY } from 'Queries';
import { ROUTES } from 'Constants';
import { showToastError, showToastSuccess } from 'Utils';

import * as Styled from '../generalStyled';

import { getColumns } from './columns';

export const DeviceTemplates: React.FC = () => {
    const navigate = useNavigate();

    const [isOpenedModal, setIsOpenedModal] = useState<boolean>(false);
    const [initialLoading, setInitialLoading] = useState<boolean>(true);
    const [deletionId, setDeletionId] = useState<string>(null as string);

    const { data, loading }: QueryResult<GetDeviceTemplateQueryType> = useQuery(GET_DEVICE_TEMPLATES_QUERY, {
        fetchPolicy: 'cache-and-network',
    });

    const deviceTemplates = data?.getDeviceTemplates?.deviceTemplates || [] as DeviceTemplate[];
    const deviceTemplateTopics = data?.getDeviceTemplates?.deviceTemplateTopics || [] as DeviceTemplateTopic[];

    const [deleteDeviceTemplateMutation] = useMutation<
        DeleteDeviceTemplateMutation,
        DeleteDeviceTemplateMutationVariables
    >(DELETE_DEVICE_TEMPLATE_MUTATION, {
        refetchQueries: [GET_DEVICE_TEMPLATES_QUERY],
        onCompleted: () => {
            showToastSuccess('Successfully deleted');
            handleModalClose();
        },
        onError: () => {
            showToastError('Deletion has failed');
        },
    });

    useEffect(
        () => {
            if(!loading) {
                setInitialLoading(false);
            }
        }, [loading]
    );

    const handleDelete = () => deleteDeviceTemplateMutation({ variables: { _id: deletionId } });

    const handleModalClose = () => {
        setIsOpenedModal(false);
        setDeletionId(null);
    };

    const onAddButtonClick = () => {
        navigate(ROUTES.devices.deviceTemplates.add);
    };

    const editDeviceTemplate = (id: string) => {
        navigate(`${id}/${ROUTES.devices.deviceTemplates.edit}`);
    };

    const deleteDeviceTemplate = (id: string) => {
        setDeletionId(id);
        setIsOpenedModal(true);
    };

    const templatesData = useMemo(
        () => deviceTemplates.map((template) => {
            const topic = deviceTemplateTopics.find((topic) => topic.name === template.topic);
            return { ...template, topic: topic.label };
        }),
        [deviceTemplates, deviceTemplateTopics]
    );

    if (initialLoading) {
        return <Loader />;
    }

    const columns = getColumns(editDeviceTemplate, deleteDeviceTemplate);

    return (
        <>
            <Styled.Wrapper>
                <Styled.AddButton onClick={onAddButtonClick} buttonType="transparent">
                    <Plus />
                    Add template
                </Styled.AddButton>
                <Table
                    columns={columns}
                    data={templatesData}
                    wrappedTable={true}
                />
            </Styled.Wrapper>
            <Modal isOpened={isOpenedModal} handleClose={handleModalClose}>
                <DeleteModal
                    title="Device template deletion"
                    description="Are you sure, you want to delete this template?"
                    cancelAction={handleModalClose}
                    deleteAction={handleDelete}
                />
            </Modal>
        </>
    );
};
