import styled, { ThemeProps } from 'styled-components';
import { NavLink } from 'react-router-dom';

import { Theme } from 'Types';

export const Wrapper = styled.div`
    margin-top: 36px;
`;

export const List = styled.div`
    display: flex;
    width: fit-content;
    border-bottom: 1px solid ${({ theme }: ThemeProps<Theme>) => theme.colors.border};
`;

export const Item = styled(NavLink)`
    display: block;
    padding: 10px;
    margin-right: 30px;
    color: ${({ theme }: ThemeProps<Theme>) => theme.colors.title};
    position: relative;
    font-size: 22px;
    font-weight: bold;

    &:last-child {
        margin-right: 0;
    }

    &:hover {
        color: ${({ theme }: ThemeProps<Theme>) => theme.colors.active};
    }

    &.active {
        color: ${({ theme }: ThemeProps<Theme>) => theme.colors.active};

        &::after {
            content: '';
            position: absolute;
            left: 0;
            bottom: -2px;
            width: 100%;
            height: 4px;
            background-color: ${({ theme }: ThemeProps<Theme>) => theme.colors.active};
        }
    }
`;
