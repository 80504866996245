import styled from 'styled-components';

import { card } from 'Styles';
import { IconButton as IButton } from 'Components';
import { ReactComponent as EditIcon } from 'Assets/icons/write.svg';

export const Wrapper = styled.div`
    width: 100%;
`;

export const TableWrapper = styled.div`
    ${card}
    margin-top: 2rem;
`;

export const IconButton = styled(IButton)`
    margin: 0.5rem 0 0.5rem 0.5rem;
`;

export const Edit = styled(EditIcon)`
    cursor: pointer;
`;
