import { useState, useMemo } from 'react';

import { getSimplifiedNumber } from 'Utils';
import { ReactComponent as LineChartIcon } from 'Assets/icons/pre-line-graph.svg';
import { ReactComponent as BarChartIcon } from 'Assets/icons/bar_chart.svg';
import { ReactComponent as NumbersIcon } from 'Assets/icons/chr-number.svg';
import { ReactComponent as DeviceIcon } from 'Assets/icons/devices_i.svg';

import { DataType } from '../types';
import * as Styled from '../styled';
import { IWidget } from '../interfaces';

import { DevicesLineChart, DevicesBarChart, DevicesTextWidget } from './components';

export const DevicesWidget: React.FC<IWidget> = ({ total }) => {
    const [type, setType] = useState<DataType>('LineChart');
    const dataVisualisation = useMemo(() => {
        switch (type) {
            case 'LineChart':
                return <DevicesLineChart />;
            case 'BarChart':
                return <DevicesBarChart />;
            default:
            case 'Numbers':
                return <DevicesTextWidget />;
        }
    }, [type]);
    
    return (
        <Styled.Card>
            <Styled.Title>
                Devices
            </Styled.Title>
            <Styled.MainStat>
                <Styled.Total>
                    <Styled.MainLabel>
                        Total
                    </Styled.MainLabel>
                    <Styled.MainCount>
                        {getSimplifiedNumber(total)}
                    </Styled.MainCount>
                </Styled.Total>
                <Styled.Icon>
                    <DeviceIcon />
                </Styled.Icon>
            </Styled.MainStat>
            <Styled.HDivider />
            <Styled.Details>
                <Styled.DataTypes>
                    <Styled.DataType
                        $active={type === 'LineChart'}
                        onClick={() => setType('LineChart')}
                    >
                        <LineChartIcon />
                    </Styled.DataType>
                    <Styled.DataType
                        $active={type === 'BarChart'}
                        onClick={() => setType('BarChart')}
                    >
                        <BarChartIcon />
                    </Styled.DataType>
                    <Styled.DataType
                        $active={type === 'Numbers'}
                        onClick={() => setType('Numbers')}
                    >
                        <NumbersIcon />
                    </Styled.DataType>
                </Styled.DataTypes>
                { dataVisualisation }    
            </Styled.Details>
        </Styled.Card>
    );
};

