import styled, { ThemeProps } from 'styled-components';

import { Button } from 'Components';
import { Theme } from 'Types';

type NetworkType = ThemeProps<Theme> & ({
    $isLora: boolean;
    $isLTE: boolean;
});

export const Wrapper = styled.div`
    margin-top: 0.5rem;
    width: 100%;
`;

export const AddButton = styled(Button)`
    margin-bottom: 10px;
    font-size: 0.875rem;
    padding: 0.5rem 1.5rem;
    border: none;
    box-shadow: none;
    svg {
        margin-right: 8px;
        margin-top: -2px;
    }

    &:focus, &:hover, &:active {
        border: none;
        box-shadow: none;
        color: ${({ theme }: ThemeProps<Theme>) => theme.colors.active};
    }
`;

export const Network = styled.span`
    font-size: 24px;
    margin-right: 10px;
    color: ${({ theme }: ThemeProps<Theme>) => theme.colors.title};

    svg {
        width: ${({ $isLora, $isLTE }: NetworkType) => {
        if ($isLora) {
            return '1.6em';
        } else if ($isLTE) {
            return '1.2em';
        }
    }}
    }
`;

export const ImageView = styled.img`
    max-width: 100px;
    max-height: 100px;
`;
