import styled, { ThemeProps } from 'styled-components';

import { Theme } from 'Types';
import { card } from 'Styles';

export const Card = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 2rem;
    ${card}
`;

export const HDivider = styled.span`
    height: 1px;
    width: 100%;
    margin-bottom: 1.5rem;
    background-color: ${({ theme }: ThemeProps<Theme>) => theme.colors.border};
`;

export const Details = styled.span`
    display: flex;
    align-items: center;
    flex-grow: 1;
    flex-direction: row;
    gap: calc(3rem + 1px);
`;

export const DetailsList = styled.ul`
    flex-grow: 1;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 0;
    &:not(:first-of-type) {
        position: relative;
        &:before {
            position: absolute;
            content: '';
            height: 100%;
            width: 1px;
            top: 0;
            left: -1.5rem;
            background-color: ${({ theme }: ThemeProps<Theme>) => theme.colors.border};
        }
    }
`;

export const DetailsItem = styled.li`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 0.875rem;
    
`;
