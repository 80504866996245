import styled, { ThemeProps } from 'styled-components';

import { Theme } from 'Types';

export const Stepper = styled.div`
    display: flex;
    align-items: center;
`;

export const Number = styled.div`
    color: ${({ theme }: ThemeProps<Theme>) => theme.colors.title};
    margin-right: 26px;
    font-weight: bold;

    & span:first-child{
        font-size: 26px;
    }

    & span:last-child{
        font-size: 20px;
    }
`;

export const Block = styled.div`
    width: 700px;
    height: 2px;
    background-color: ${({ theme }: ThemeProps<Theme>) => theme.colors.border};
    position: relative;
`;

export const Filled = styled.div`
    position: absolute;
    height: 6px;
    top: -2px;
    background-color: ${({ theme }: ThemeProps<Theme>) => theme.colors.active};
    transition: width 0.4s linear;
`;
