import { useQuery, QueryResult } from '@apollo/client';
import moment from 'moment';

import { DailyStatsQueries, DailyStatsInputType, DailyUsersStatsType, DashboardCardStatsQueries, DashboardCardStats } from 'Types';
import { USERS_DAILY_STATISTICS_QUERY, ACTIVE_USERS_BY_DATERANGES } from 'Queries';

import { NumbersList } from '../../../components';

export const UsersTextWidget: React.FC = () => {
    const { data: dailyUsersStatsData }: QueryResult<
        DailyStatsQueries,
        DailyStatsInputType
    > = useQuery(USERS_DAILY_STATISTICS_QUERY, {
        variables: {
            days: 30,
        },
    });
    const dailyUsersStats = dailyUsersStatsData?.getDailyUsersStats ?? [] as DailyUsersStatsType[];
    
    const { data: activeUsersdata }: QueryResult<DashboardCardStatsQueries> = useQuery(ACTIVE_USERS_BY_DATERANGES);
    const activeUsers = activeUsersdata?.getActiveUsersCountsByDate ?? {} as DashboardCardStats;
    const active = {
        title: 'active',
        ...activeUsers,
    };

    const added = {
        title: 'new',
        yesterday: dailyUsersStats[dailyUsersStats.length - 1]?.added || 0,
        thirtyDays: 0,
        startOfMonth: 0,
    };
    added.thirtyDays = dailyUsersStats.reduce((prev, curr) => prev + curr.added, 0);
    added.startOfMonth = dailyUsersStats
        .filter((obj) => moment(obj.date).isAfter(moment().startOf('month')))
        .reduce((prev, curr) => prev + curr.added, 0);
    
    return (
        <NumbersList list={[added, active]} />
    );
};
