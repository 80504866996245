import { PropsWithChildren } from 'react';

import { IIconButton } from './interfaces';
import * as Styled from './styled';

export const IconButton: React.FC<PropsWithChildren<IIconButton>> = ({
    onClick,
    children,
    Icon,
    className,
    buttonType,
    bordered,
    isTextBold,
    disabled,
}) => (
    <Styled.Button
        className={className}
        $buttonType={buttonType}
        $bordered={bordered}
        $textBold={isTextBold}
        onClick={onClick}
        icon={Icon}
        disabled={disabled}
    >
        {children}
    </Styled.Button>
);

IconButton.defaultProps = {
    children: null,
    className: '',
    buttonType: 'transparent',
    bordered: false,
    isTextBold: false,
    disabled: false,
};
