import { gql } from '@apollo/client';

export const SYSTEM_ADMIN_LOGIN_MUTATION = gql`
    mutation systemAdminLogin($input: LoginInput!) {
        systemAdminLogin(input: $input) {
            status
        }
    }
`;

export const SYSTEM_ADMIN_LOGOUT_MUTATION = gql`
    mutation systemAdminLogout {
        systemAdminLogout {
            status
        }
    }
`;

export const GET_ADMIN_DATA_QUERY = gql`
    query getAdminSession {
        getAdminSession {
            id
            email
            fullName
            scope
        }
    }
`;
