import { toast, ToastOptions } from 'react-toastify';

const options = {
    position: 'top-right',
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    theme: 'colored',
} as ToastOptions;

export function showToastError(message: string) {
    return toast.error(`Error: ${message}`, { ...options, type: 'error' });
}

export function showToastSuccess(message: string) {
    return toast.success(message, { ...options, type: 'success' });
}

export function showToastInfo(message: string) {
    return toast.success(message, { ...options, type: 'info' });
}
