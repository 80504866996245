import { RouteObject, Navigate } from 'react-router-dom';

import {
    Login,
    Home,
    Devices,
    DeviceTemplates,
    ShieldTemplates,
    AddDeviceTemplate,
    EditDeviceTemplate,
    AddShieldTemplate,
    EditShieldTemplate,
    AddDeviceTopicTemplate,
    DeviceTopicTemplates,
    EditDeviceTopicTemplate,
    Networks,
    Notifications,
} from 'Pages';
import { ROUTES } from 'Constants';

export enum LAYOUTS {
    MAIN = 'main',
    AUTH = 'auth',
}

export interface RouteObjectLayouted extends RouteObject {
    layout: LAYOUTS;
}

export const routesConfig: RouteObjectLayouted[] = [
    {
        path: ROUTES.auth.login,
        element: <Login />,
        layout: LAYOUTS.AUTH,
    },
    {
        index: true,
        path: ROUTES.main,
        element: <Home />,
        layout: LAYOUTS.MAIN,
    },
    {
        path: ROUTES.devices.main,
        element: <Devices />,
        layout: LAYOUTS.MAIN,
        children: [
            {
                path: ROUTES.devices.deviceTemplates.main,
                element: <DeviceTemplates />,
            },
            {
                path: ROUTES.devices.shieldTemplates.main,
                element: <ShieldTemplates />,
            },
            {
                path: ROUTES.devices.deviceTopicTemplates.main,
                element: <DeviceTopicTemplates />,
            },
            {
                path: '',
                element: <Navigate to={ROUTES.devices.deviceTemplates.main} replace />,
            },
        ],
    },
    {
        path: `${ROUTES.devices.main}/${ROUTES.devices.deviceTemplates.main}/${ROUTES.devices.deviceTemplates.add}`,
        element: <AddDeviceTemplate />,
        layout: LAYOUTS.MAIN,
    },
    {
        path: `${ROUTES.devices.main}/${ROUTES.devices.deviceTemplates.main}/:id/${ROUTES.devices.deviceTemplates.edit}`,
        element: <EditDeviceTemplate />,
        layout: LAYOUTS.MAIN,
    },
    {
        path: `${ROUTES.devices.main}/${ROUTES.devices.shieldTemplates.main}/${ROUTES.devices.shieldTemplates.add}`,
        element: <AddShieldTemplate />,
        layout: LAYOUTS.MAIN,
    },
    {
        path: `${ROUTES.devices.main}/${ROUTES.devices.shieldTemplates.main}/:id/${ROUTES.devices.shieldTemplates.edit}`,
        element: <EditShieldTemplate />,
        layout: LAYOUTS.MAIN,
    },
    {
        path: `${ROUTES.devices.main}/${ROUTES.devices.deviceTopicTemplates.main}/${ROUTES.devices.deviceTopicTemplates.add}`,
        element: <AddDeviceTopicTemplate />,
        layout: LAYOUTS.MAIN,
    },
    {
        path: `${ROUTES.devices.main}/${ROUTES.devices.deviceTopicTemplates.main}/:id/${ROUTES.devices.deviceTopicTemplates.edit}`,
        element: <EditDeviceTopicTemplate />,
        layout: LAYOUTS.MAIN,
    },
    {
        path: ROUTES.networks,
        element: <Networks />,
        layout: LAYOUTS.MAIN,
    },
    {
        path: ROUTES.notifications,
        element: <Notifications />,
        layout: LAYOUTS.MAIN,
    },
];
