import { Dropdown } from 'Components';
import { ColumnType } from 'Types';
import { getTimeDiff } from 'Utils';

export const getColumns = (
    editTemplate: (id: string) => void,
    deleteTemplate: (id: string) => void
): ColumnType[] => {
    const dropdownActions = [
        {
            executedFunction: editTemplate,
            title: 'Edit',
        },
        {
            executedFunction: deleteTemplate,
            title: 'Delete',
        },
    ];

    return [
        {
            dataIndex: 'label',
            title: 'Label',
        },
        {
            dataIndex: 'name',
            title: 'Tag',
        },
        {
            dataIndex: 'createdAt',
            title: 'Created',
            render: ((data) => {
                const date = data as number;
                return getTimeDiff(date);
            }),
        },
        {
            title: 'Actions',
            dataIndex: '_id',
            width: '15%',
            render: (id) => {
                const receivedId = id as string;
                return (
                    <Dropdown
                        actions={dropdownActions}
                        id={receivedId}
                    />
                );
            },
        },
    ];
};
