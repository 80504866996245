import { useQuery, QueryResult } from '@apollo/client';
import moment from 'moment';

import { DailyStatsQueries, DailyStatsInputType, DailyMessagesStatsType } from 'Types';
import { MESSAGES_DAILY_STATISTICS_QUERY } from 'Queries';

import { NumbersList } from '../../../components';

export const MessagesTextWidget: React.FC = () => {
    const { data: dailyMessagesStatsData }: QueryResult<
        DailyStatsQueries,
        DailyStatsInputType
    > = useQuery(MESSAGES_DAILY_STATISTICS_QUERY, {
        variables: {
            days: 30,
        },
    });
    const dailyMessagesStats = dailyMessagesStatsData?.getDailyMessagesStats ?? [] as DailyMessagesStatsType[];
    const total = {
        title: 'total',
        yesterday: dailyMessagesStats[dailyMessagesStats.length - 1]?.total || 0,
        thirtyDays: 0,
        startOfMonth: 0,
    };
    total.thirtyDays = dailyMessagesStats.reduce((prev, curr) => prev + curr.total, 0);
    const startOfMonth = moment().startOf('month');
    total.startOfMonth = dailyMessagesStats
        .filter((obj) => moment(obj.date).isAfter(startOfMonth))
        .reduce((prev, curr) => prev + curr.total, 0);
    
    const bandwidth = {
        title: 'bandwidth',
        unit: 'byte',
        yesterday: dailyMessagesStats[dailyMessagesStats.length - 1]?.bandwidth || 0,
        thirtyDays: 0,
        startOfMonth: 0,
    };
    bandwidth.thirtyDays = dailyMessagesStats.reduce((prev, curr) => prev + curr.bandwidth, 0);
    bandwidth.startOfMonth = dailyMessagesStats
        .filter((obj) => moment(obj.date).isAfter(startOfMonth))
        .reduce((prev, curr) => prev + curr.bandwidth, 0);
    
    return (
        <NumbersList list={[total, bandwidth]} />
    );
};
