import { useMutation } from '@apollo/client';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';

import { SYSTEM_ADMIN_LOGIN_MUTATION } from 'Queries';
import { showToastError, showToastSuccess } from 'Utils';
import { ROUTES } from 'Constants';
import { LoginSystemAdminMutation, LoginSystemAdminVariables } from 'Types';

import { FormContent } from './formContent';
import { initialValues, validationSchema } from './config';
import { IValues } from './interfaces';
import * as Styled from './styled';

export const Login: React.FC = () => {
    const navigate = useNavigate();
    const [loginMutation] = useMutation<
        LoginSystemAdminMutation,
        LoginSystemAdminVariables
    >(SYSTEM_ADMIN_LOGIN_MUTATION);

    const onSubmitLogin = async (values: IValues) => {
        try {
            await loginMutation({
                variables: { input: values },
                onCompleted: (data) => {
                    const status = data?.systemAdminLogin?.status;
                    if (status === 'Authorized') {
                        showToastSuccess('Successfully logged in');
                        navigate(ROUTES.main);
                    } else {
                        showToastError('Wrong username or password');
                    }
                },
            });
        } catch (error) {
            showToastError('Test');
        }
    };

    return (
        <>
            <Styled.Content>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmitLogin}
                >
                    {(props) => (
                        <FormContent
                            {...props}
                        />
                    )}
                </Formik>
            </Styled.Content>
        </>
    );
};
