import * as Yup from 'yup';

import { Notification } from 'Types';

export const SYSTEM_NOTIFICATIONS_TYPES = {
    INFO: 'info',
    WARNING: 'warning',
    ERROR: 'error',
};

export const SYSTEM_NOTIFICATION_TYPES_VALUES = [
    SYSTEM_NOTIFICATIONS_TYPES.INFO,
    SYSTEM_NOTIFICATIONS_TYPES.WARNING,
    SYSTEM_NOTIFICATIONS_TYPES.ERROR,
];

export const validationSchema = Yup.object().shape({
    title: Yup.string().required('Title is required.'),
    content: Yup.string().required('Content is required.'),
    type: Yup.string().required('Type is required.'),
});

export const initialValues: Notification = {
    title: '',
    content: '',
    type: SYSTEM_NOTIFICATIONS_TYPES.INFO,
    active: false,
};
