import styled, { css, ThemeProps } from 'styled-components';
import { ResponsiveContainer as RechartsContainer } from 'recharts';

import { card } from 'Styles';
import { Theme } from 'Types';

import { OptionType } from './types';

type OptionTypeStyled = ThemeProps<Theme> & OptionType;

export const Card = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 400px;
    width: 100%;
    padding: 2rem;
    padding-bottom: 1rem;
    height: fit-content;
    ${card}
`;

export const Title = styled.span`
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
`;

export const MainStat = styled.span`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
`;

export const Total = styled.span`
    display: flex;
    flex-direction: column;
`;

export const MainLabel = styled.span`
    font-size: 1rem;
    font-weight: 400;
`;

export const MainCount = styled.span`
    font-size: 2rem;
    font-weight: 600;
    line-height: 1;
`;

export const HDivider = styled.span`
    height: 1px;
    width: 100%;
    margin-bottom: 0.5rem;
    background-color: ${({ theme }: ThemeProps<Theme>) => theme.colors.border};
`;

export const Details = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 230px;
`;

export const Icon = styled.div`
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
    position: relative;
    background-color: ${({ theme }: ThemeProps<Theme>) => theme.colors.active_light};

    svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: ${({ theme }: ThemeProps<Theme>) => theme.colors.active};
        font-size: 2rem;
    }
`;

export const Options = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 0.5rem;
    margin-bottom: 2rem;
    margin-top: -2.25rem;
`;

export const Option = styled.div`
    color: ${({ theme }) => theme.colors.text};
    font-size: 0.75rem;
    padding: 0.25rem 1rem;
    border-radius: 0.5rem;
    user-select: none;
    cursor: pointer;
    ${({ $active, theme }: OptionTypeStyled) => $active && css`
        font-weight: bold;
        color: ${theme.colors.active};
        background-color: ${theme.colors.active_light};
    `};
`;

export const ResponsiveContainer = styled(RechartsContainer)`
    .recharts-text {
        fill: rgba(191, 191, 191, 1);
        font-size: 10px;
    }
    .recharts-cartesian-axis-line {
        stroke: rgba(191, 191, 191, 1);
    }
    .recharts-cartesian-axis-tick-line {
        opacity: 0;
    }
`;

export const DataTypes = styled.div`
    display: flex;
    flex-direction: row;
    gap: 0.75rem;
    margin-top: 0.5rem;
`;

export const DataType = styled.button`
    z-index: 1000;
    cursor: pointer;
    font-size: 1.5rem;
    color: ${({ theme }: OptionTypeStyled) => theme.colors.text};
    opacity: 0.3;
    background: transparent;
    ${({ $active, theme }: OptionTypeStyled) => $active && css`
        color: ${theme.colors.active};
        opacity: 1;
    `};
`;

export const DetailsNumbers = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    justify-content: center;
    margin-top: 1rem;
    gap: 15%;
`;

export const DetailsList = styled.ul`
    display: flex;
    flex-grow: 1;
    margin-bottom: 0;
    justify-content: space-between;
    list-style-type: none;
    flex-direction: column;
    padding: 1.5rem 0;
    &:not(:first-of-type) {
        position: relative;
        &:before {
            position: absolute;
            content: '';
            height: 80%;
            width: 1px;
            top: 10%;
            left: -15%;
            background-color: ${({ theme }: ThemeProps<Theme>) => theme.colors.border};
        }
    }
`;
export const DetailsItem = styled.li`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 0.875rem;
    &:not(:last-of-type) {
        margin-bottom: 1rem;
    }
`;

export const DetailsLabel = styled.span`
`;

export const DetailsCount = styled.span`
    font-weight: 600;
`;
