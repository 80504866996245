import { useQuery, QueryResult } from '@apollo/client';

import { ACCOUNTS_COUNT_BY_ACCOUNTTYPE } from 'Queries';
import { CountByTypeQueries, CountByType } from 'Types';

import { PieChart } from '../../../components';

export const UsersPieChart: React.FC = () => {
    const { data }: QueryResult<CountByTypeQueries> = useQuery(ACCOUNTS_COUNT_BY_ACCOUNTTYPE);
    const countByAccountType = data?.getAccountsCountByType ?? [] as CountByType[];

    return (
        <PieChart
            chartData={countByAccountType}
            nameKey="type"
            dataKey="count"
        />
    );
};
