import { FieldInputProps } from 'formik';

import { emptyFunction } from 'Utils';

import { ICheckbox } from './interfaces';
import * as Styled from './styled';

export const Checkbox: React.FC<ICheckbox> = ({
    title,
    value,
    checked,
    field,
    handleCheckbox,
    disabled,
}) => (
    <Styled.Wrapper>
        <Styled.Container disabled={disabled}>
            {title}
            {field && Object.values(field).length ? (
                <input
                    value={field.value}
                    name={field.name}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    type="checkbox"
                    checked={field.value}
                    disabled={disabled}
                />
            ) : (
                <input
                    type="checkbox"
                    value={value}
                    checked={checked}
                    onChange={handleCheckbox}
                    disabled={disabled}
                />
            )}
            <Styled.Fake />
        </Styled.Container>
    </Styled.Wrapper>
);

Checkbox.defaultProps = {
    value: '',
    checked: false,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    field: {} as FieldInputProps<any>,
    handleCheckbox: emptyFunction,
    title: '',
    disabled: false,
};
