import styled, { ThemeProps } from 'styled-components';

import { Theme } from 'Types';

export const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;

export const Header = styled.div`
    height: 145px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid ${({ theme }: ThemeProps<Theme>) => theme.colors.border};

    & > * {
        width: 226px;
    }
`;

export const ContentWrapper = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    overflow: auto;
`;

export const Content = styled.div`
    flex: auto;
    max-width: 500px;
    padding: 50px 20px;
`;
