import { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useQuery, useMutation, QueryResult } from '@apollo/client';

import { PageHeader, Loader } from 'Components';
import { showToastError, showToastSuccess } from 'Utils';
import {
    GET_DEVICE_AVAILABLE_TOPICS_QUERY,
    CREATE_DEVICE_TOPIC_TEMPLATE_MUTATION,
} from 'Queries';
import { CreateDeviceTemplateTopicMutation, CreateDeviceTemplateTopicVariables, DeviceTemplateTopic, GetDeviceTemplateTopics } from 'Types';

import { FormContent } from './formContent';
import { getInitialValues, validationSchema } from './config';
import { IValues } from './interfaces';
import * as Styled from './styled';

export const AddDeviceTopicTemplate: React.FC = () => {
    const [initialLoading, setInitialLoading] = useState<boolean>(true);
    const navigate = useNavigate();

    const { data, loading }: QueryResult<GetDeviceTemplateTopics> = useQuery(GET_DEVICE_AVAILABLE_TOPICS_QUERY, {
        fetchPolicy: 'cache-and-network',
    });
    const deviceTopicTemplates = data?.getDeviceTemplateTopics || [] as DeviceTemplateTopic[];

    const [createDeviceTopicTemplateMutation] = useMutation<
        CreateDeviceTemplateTopicMutation,
        CreateDeviceTemplateTopicVariables
    >(CREATE_DEVICE_TOPIC_TEMPLATE_MUTATION, {
        refetchQueries: [GET_DEVICE_AVAILABLE_TOPICS_QUERY],
        onCompleted: (data) => {
            if (data?.createDeviceTemplateTopic) {
                showToastSuccess('Successfully created');
                navigate(-1);
            } else {
                showToastError('Could not create a new template');
            }
        },
    });

    useEffect(
        () => {
            if (!loading) {
                setInitialLoading(false);
            }
        },
        [loading]
    );

    const navigateBack = () => navigate(-1);

    const onSubmit = async (values: IValues): Promise<void> => {
        const alreadyExists = deviceTopicTemplates.find((topic) => values.name === topic.name || values.label === topic.label);

        if (alreadyExists) {
            showToastError('Such label or tag already exists');
            return;
        }

        createDeviceTopicTemplateMutation({ variables: { input: values } });
    };

    if (initialLoading) {
        return <Loader />;
    }

    return (
        <>
            <PageHeader
                title="Add device topic template"
                description={[
                    'The changes you make, will be applied to main service to be displayed in section of adding a new device',
                ]}
            />
            <Styled.Content>
                <Formik
                    initialValues={getInitialValues()}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {(props) => (
                        <FormContent
                            {...props}
                            onClickBack={navigateBack}
                            formMode="New"
                        />
                    )}
                </Formik>
            </Styled.Content>
        </>
    );
};
