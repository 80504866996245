import styled, { ThemeProps } from 'styled-components';
import { Table as AntTable } from 'antd';

import { hexToRgb } from 'Utils';
import { Theme } from 'Types';
import { shadowSm } from 'Styles';

import { Dropdown } from '../dropdown';

type TableWrapperType = ThemeProps<Theme> & ({
    $clickableRows: boolean;
    $wrappedTable: boolean;
});

export const TableWrapper = styled(AntTable)`
    font-size: 14px;
    ${shadowSm}
    padding: 0;
    border-radius: 0.5rem !important;
    background: white;
    border: 1px solid ${({ theme }: TableWrapperType) => theme.colors.border};

    ${({ $wrappedTable, theme }: TableWrapperType) => $wrappedTable && `
        box-shadow: none;
        border: 0px;
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
        border-top: 1px solid ${theme.colors.border} !important;
        .ant-table-thead {
            background-color: rgba(${hexToRgb(theme.colors.border)}, 0.3);
        }
    `}

    .ant-table {
        background: transparent;
    }
    
    ${({ $clickableRows }: TableWrapperType) => $clickableRows && `
        .ant-table-row {
           cursor: pointer;
       }
    `}

    table {
        border: none;
        border-bottom: none;
        color: ${({ theme }: TableWrapperType) => theme.colors.text};
    }

    .ant-table-row,
    .ant-table-thead {
        tr {
            border-bottom: 1px solid ${({ theme }: TableWrapperType) => theme.colors.border} !important;
        }
        td:first-of-type, th:first-of-type {
            padding: 0.5rem 1.5rem 0.5rem 1.5rem !important;
        }
    }
    tr.ant-table-row:hover {
        td {
            background: rgba(${({ theme }: TableWrapperType) => hexToRgb(theme.colors.active)}, 0.1);
        }
    }

    .ant-table-row-selected {
        &:hover .ant-table-cell {
            background: rgba(${({ theme }: TableWrapperType) => hexToRgb(theme.colors.active)}, 0.1);
        }

        .ant-table-cell {
            background: rgba(${({ theme }: TableWrapperType) => hexToRgb(theme.colors.active)}, 0.1);
        }

    }

    .ant-table-thead {
        .ant-table-cell {
            background: transparent;
            font-weight: 600;
            font-size: 0.875rem;
            padding-top: 1rem !important;
            padding-bottom: 1rem !important;
            &:before {
                display: none;
            }
            ${({ $wrappedTable }: TableWrapperType) => $wrappedTable && `
                padding-top: 0.75rem !important;
                padding-bottom: 0.75rem !important;
                font-size: 0.75rem;
                color: #595959;
                text-transform: uppercase;
            `}
        }
    }

    .ant-table-row {
        .ant-table-cell {
            padding-top: 1.25rem !important;
            padding-bottom: 1.25rem !important;
        }
    }

    .ant-checkbox {
        .ant-checkbox-inner {
            border-color: ${({ theme }: TableWrapperType) => theme.colors.border};
        }
    }

    .ant-checkbox-input {
        &:hover + .ant-checkbox-inner,
        &:focus + .ant-checkbox-inner {
            border-color: ${({ theme }: TableWrapperType) => theme.colors.active};
        }

        &:checked + .ant-checkbox-inner {
            border-color: ${({ theme }: TableWrapperType) => theme.colors.active};
            background-color: ${({ theme }: TableWrapperType) => theme.colors.active};
        }
    }

    .ant-checkbox-checked {
        .ant-checkbox-inner {
            background: ${({ theme }: TableWrapperType) => theme.colors.active};
            border-color: ${({ theme }: TableWrapperType) => theme.colors.active};

            &::after {
                border-color: ${({ theme }: TableWrapperType) => theme.colors.activeText};
            }
        }

        &::after {
            border-color: ${({ theme }: TableWrapperType) => theme.colors.active};
        }
    }

    .ant-radio {
        .ant-radio-inner {
            border-color: ${({ theme }: TableWrapperType) => theme.colors.border};
        }
    }

    .ant-radio-input {
        &:hover + .ant-radio-inner,
        &:focus + .ant-radio-inner {
            border-color: ${({ theme }: TableWrapperType) => theme.colors.border};
        }

        &:checked + .ant-radio-inner {
            border-color: ${({ theme }: TableWrapperType) => theme.colors.active};
            background-color: ${({ theme }: TableWrapperType) => theme.colors.active};
        }
    }

    .ant-radio-checked {
        .ant-radio-inner {
            border-color: ${({ theme }: TableWrapperType) => theme.colors.active};

            &::after {
                background-color: ${({ theme }: TableWrapperType) => theme.colors.active};
            }
        }

        &::after {
            border-color: ${({ theme }: TableWrapperType) => theme.colors.active};
        }
    }

    .ant-checkbox-indeterminate .ant-checkbox-inner {
        background-color: ${({ theme }: TableWrapperType) => theme.colors.activeText};

        &::after {
            background-color: ${({ theme }: TableWrapperType) => theme.colors.active};
        }
    }
`;

export const SearchWrapper = styled.div`
    margin-bottom: 22px;
`;

export const PaginationWrapper = styled.div`
    padding: 16px 0;
    display: flex;
`;

export const PaginationButton = styled.button`
    font-size: 14px;
    display: flex;
    align-items: center;
    color: ${({ theme }: ThemeProps<Theme>) => theme.colors.title};
    cursor: pointer;
    background: #fff;

    &:first-child {
        margin-right: 15px;
    }

    svg {
        font-size: 24px;
        line-height: 0;
    }

    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
`;

export const PageSizeWrapper = styled.div`
    position: absolute;
    right: 0;

    span {
        margin-right: 10px;
    }
`;

export const DropdownButton = styled(Dropdown)`
    background-color: transparent;
    min-width: 30px;
    border: 1px solid rgba(${({ theme }: ThemeProps<Theme>) => hexToRgb(theme.colors.text)}, 0.2);
    display: inline-block;
`;

export const TableFooter = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
