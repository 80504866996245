import { useContext } from 'react';
import { useMutation } from '@apollo/client';
import { Dropdown, Menu } from 'antd';

import { sessionContext } from 'Context';
import { GET_ADMIN_DATA_QUERY, SYSTEM_ADMIN_LOGOUT_MUTATION } from 'Queries';
import { SystemAdminLogoutMutationType } from 'Types';
import { showToastError } from 'Utils';
import { ReactComponent as LogOutIcon } from 'Assets/icons/logout.svg';
import { ReactComponent as AccountIcon } from 'Assets/icons/user.svg';

import * as User from './styled';
import * as Header from '../../styled';

export const UserProfile: React.FC = () => {
    const { session } = useContext(sessionContext);

    const [logoutMutation] = useMutation<SystemAdminLogoutMutationType> (SYSTEM_ADMIN_LOGOUT_MUTATION, {
        refetchQueries: [GET_ADMIN_DATA_QUERY],
    });

    const handleLogout = async () => {
        try {
            await logoutMutation();
        } catch (error) {
            showToastError('Logout failed');
        }
    };

    const MenuItems = (
        <User.Menu>
            <Menu.Item>
                <User.Account
                    to={'test'}
                    data-cy="account-button"
                >
                    <AccountIcon />
                    My profile
                </User.Account>
            </Menu.Item>
            <User.Divider />
            <Menu.Item>
                <User.LogOut
                    data-cy="logout-button"
                    onClick={handleLogout}
                >
                    <LogOutIcon />
                    Log out
                </User.LogOut>
            </Menu.Item>
        </User.Menu>
    );

    return (
        <Dropdown overlay={MenuItems}>
            <User.Owner data-cy="account-button-open">
                <Header.CircleIcon>
                    <AccountIcon />
                </Header.CircleIcon>
                <User.UserInfo>
                    <User.UserName>
                        {session?.fullName?.split(' ')[0]}
                    </User.UserName>
                    <User.UserMail>
                        {session?.email}
                    </User.UserMail>
                </User.UserInfo>
            </User.Owner>
        </Dropdown>
    );
};
