import { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery, useMutation, QueryResult } from '@apollo/client';

import { PageHeader, Loader } from 'Components';
import {
    GET_PLATFORM_NETWORKS_QUERY,
    GET_SHIELD_TEMPLATE_BY_ID,
    UPDATE_SHIELD_TEMPLATE_MUTATION,
    GET_SHIELD_TEMPLATES_QUERY,
} from 'Queries';
import {
    GetPlatformNetworksQueryType,
    GetShieldTemplateById,
    GetShieldTemplateByIdVariables,
    UpdateShieldTemplateMutation,
    UpdateShieldTemplateVariables,
} from 'Types';
import { showToastError, showToastSuccess } from 'Utils';

import { FormContent } from './formContent';
import { getInitialValues, validationSchema } from './config';
import { IValues } from './interfaces';
import * as Styled from './styled';

export const EditShieldTemplate: React.FC = () => {
    const [initialLoading, setInitialLoading] = useState<boolean>(true);
    const navigate = useNavigate();
    const { id: _id } = useParams();

    const { data: shieldData, loading: shieldLoading }: QueryResult<
        GetShieldTemplateById,
        GetShieldTemplateByIdVariables
    > = useQuery(
        GET_SHIELD_TEMPLATE_BY_ID,
        {
            variables: { _id },
            fetchPolicy: 'cache-and-network',
        }
    );
    const shieldTemplate = shieldData?.getShieldTemplateById;

    const { data: networksData, loading: networksLoading }: QueryResult<GetPlatformNetworksQueryType> = useQuery(
        GET_PLATFORM_NETWORKS_QUERY
    );
    const networks = networksData?.getPlatformNetworks || [];

    const [updateShieldTemplateMutation] = useMutation<
        UpdateShieldTemplateMutation,
        UpdateShieldTemplateVariables
    >(UPDATE_SHIELD_TEMPLATE_MUTATION, {
        refetchQueries: [GET_SHIELD_TEMPLATES_QUERY],
        onCompleted: (data) => {
            if (data?.updateShieldTemplate) {
                showToastSuccess('Successfully updated');
                navigate(-1);
            } else {
                showToastError('Could not edit a new template');
            }
        },
    });

    useEffect(
        () => {
            if (!shieldLoading && !networksLoading) {
                setInitialLoading(false);
            }
        },
        [shieldLoading, networksLoading]
    );

    const navigateBack = () => navigate(-1);

    const onSubmit = async (values: IValues): Promise<void> => {
        const { networks, ...rest } = values;
        const supportedNetworks: string[] = [];
        const unsupportedNetworks: string[] = [];

        networks.forEach((network) => {
            if(network.check) {
                supportedNetworks.push(network.key);
            } else {
                unsupportedNetworks.push(network.key);
            }
        });

        if (!supportedNetworks.length) {
            showToastError('At least one network has to be chosen');
            return;
        }

        const sensors = values.sensors.filter((sensor) => !!sensor.trim());
        const supportedDevices = values.supportedDevices.filter((device) => !!device.trim());

        const input = { ...rest, supportedNetworks, unsupportedNetworks, sensors, supportedDevices };
        updateShieldTemplateMutation({ variables: { _id, input } });
    };

    if (initialLoading) {
        return <Loader />;
    }

    return (
        <>
            <PageHeader
                title="Add shield template"
                description={[
                    'The changes you make, will be applied to main service to be displayed in section of adding a new device',
                ]}
            />
            <Styled.Content>
                <Formik
                    initialValues={getInitialValues(networks, shieldTemplate)}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {(props) => (
                        <FormContent
                            {...props}
                            onClickBack={navigateBack}
                            formMode="Edit"
                        />
                    )}
                </Formik>
            </Styled.Content>
        </>
    );
};
