import { useQuery, QueryResult } from '@apollo/client';

import { DashboardCardStatsQueries, DashboardCardStats } from 'Types';
import { DASHBOARD_PROJECTS_STATS } from 'Queries';
import { ReactComponent as ProjectsIcon } from 'Assets/icons/folder_open.svg';

import { Card } from '../components';

export const ProjectsWidget: React.FC = () => {
    const { data: dashboardPymeshsData }: QueryResult<DashboardCardStatsQueries> = useQuery(DASHBOARD_PROJECTS_STATS);
    const dashboardProjectsStats = dashboardPymeshsData?.getDashboardProjectsStats ?? {} as DashboardCardStats;

    return (
        <Card name="Projects" stats={dashboardProjectsStats} icon={<ProjectsIcon />} />
    );
};
