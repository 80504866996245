import { SyntheticEvent, useRef, useState } from 'react';

import { emptyFunction, showToastError } from 'Utils';
import { ReactComponent as Download } from 'Assets/icons/download.svg';

import { IBaseFileUploader } from './interfaces';
import * as Styled from './styled';

export const BaseFileUploader: React.FC<IBaseFileUploader> = ({
    expectedExtensions,
    disabled,
    maxSize,
    defaultName,
    onChange,
}) => {
    const fileRef = useRef<HTMLInputElement>(null);
    const [selectedFileName, setSelectedFileName] = useState<string>(null as string);
    const buttonOnClick = () => {
        fileRef?.current?.click();
    };

    const fileOnChange = (event: SyntheticEvent) => {
        const file = (event.target as HTMLInputElement).files?.[0];

        const matchResult = file.name.match(/\.([^.]*)$/);

        if (!file || !matchResult) {
            return;
        }

        const fileExtension = matchResult[1];

        if (maxSize && file.size >= maxSize) {
            const mbFileSize = Math.floor(file.size / 1000000);
            const mbMaxSize = Math.floor(maxSize / 1000000);
            showToastError(
                `You have selected a file of ${mbFileSize}MB. Your file should be less than ${mbMaxSize}MB`
            );
        }
        if (!expectedExtensions.includes(fileExtension)) {
            showToastError(
                `You have selected a file of .${fileExtension}. But your file should be ${expectedExtensions}`
            );
            return;
        }

        setSelectedFileName(file.name || defaultName);
        onChange(file);
    };

    const label =
        selectedFileName ||
        `No ${
            expectedExtensions ? `${expectedExtensions} ` : ''
        } file was selected`;

    return (
        <Styled.Wrapper>
            <Styled.FileInput
                type="file"
                ref={fileRef}
                onChange={fileOnChange}
                accept={expectedExtensions}
            />
            <Styled.Button
                isTextBold
                buttonType="filled"
                disabled={disabled}
                onClick={buttonOnClick}
                Icon={<Download />}
            >
                Upload
            </Styled.Button>
            <Styled.Label>{label}</Styled.Label>
        </Styled.Wrapper>
    );
};

BaseFileUploader.defaultProps = {
    expectedExtensions: '',
    disabled: false,
    maxSize: 0,
    defaultName: 'file.zip',
    onChange: emptyFunction,
};
