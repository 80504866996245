import styled, { keyframes, ThemeProps } from 'styled-components';

import { Theme } from 'Types';
import { hexToRgb } from 'Utils';

type WrapperProps = {
    $overlay: boolean;
};

type SpinnerProps = {
    $size: number;
};

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    width: 100%;
    height: 100%;

    ${({ $overlay }: WrapperProps) => $overlay && `
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        width: auto;
        height: auto;
    `}
`;

export const Title = styled.div`
    font-size: 50px;
    color: ${({ theme }: ThemeProps<Theme>) => theme.colors.title};
    font-weight: bold;
    text-transform: uppercase;
`;

const Load8 = keyframes`
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
`;

export const Spinner = styled.div`
    border-radius: 50%;
    width: 10em;
    height: 10em;
    margin: 60px auto;
    font-size: ${({ $size }: SpinnerProps) => `${$size / 10}px`};;
    position: relative;
    text-indent: -9999em;
    border-top: 1.1em solid rgba(${({ theme }: ThemeProps<Theme>) => hexToRgb(theme.colors.active)}, 0.2);
    border-right: 1.1em solid rgba(${({ theme }: ThemeProps<Theme>) => hexToRgb(theme.colors.active)}, 0.2);
    border-bottom: 1.1em solid rgba(${({ theme }: ThemeProps<Theme>) => hexToRgb(theme.colors.active)}, 0.2);
    border-left: 1.1em solid ${({ theme }: ThemeProps<Theme>) => theme.colors.activeText};
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    animation: ${Load8} 1.1s infinite linear;

    &:after {
        border-radius: 50%;
        width: 10em;
        height: 10em;
    }
`;
