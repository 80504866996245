import { gql } from '@apollo/client';

export const SERVICE_STATISTIC_QUERY = gql`
    query getServiceStatistic {
        getServiceStatistic {
            devicesCount
            usersCount
        }
    }
`;

export const USERS_DAILY_STATISTICS_QUERY = gql`
    query getDailyUsersStats($days: Int!) {
        getDailyUsersStats(days: $days) {
            date
            added
            removed
            active
        }
    }
`;
export const MESSAGES_DAILY_STATISTICS_QUERY = gql`
    query getDailyMessagesStats($days: Int!) {
        getDailyMessagesStats(days: $days) {
            date
            total
            bandwidth
            sigfox
            lora
            lte
            wifi
        }
    }
`;
export const DEVICES_DAILY_STATISTICS_QUERY = gql`
    query getDailyDevicesStats($days: Int!) {
        getDailyDevicesStats(days: $days) {
            date
            added
            removed
            growth
            active
        }
    }
`;

export const DEVICES_COUNTS_BY_TYPES_QUERY = gql`
    query getDevicesCountsByTypes {
        getDevicesCountsByTypes {
            type
            count
        }
    }
`;

export const DASHBOARD_PROJECTS_STATS = gql`
    query getDashboardProjectsStats {
        getDashboardProjectsStats {
            yesterday
            thirtyDays
            startOfMonth
            total
        }
    }
`;

export const DASHBOARD_PYMESHS_STATS = gql`
    query getDashboardPymeshsStats {
        getDashboardPymeshsStats {
            yesterday
            thirtyDays
            startOfMonth
            total
        }
    }
`;

export const DASHBOARD_RELEASES_STATS = gql`
    query getDashboardReleasesStats {
        getDashboardReleasesStats {
            yesterday
            thirtyDays
            startOfMonth
            total
        }
    }
`;

export const MESSAGES_TOTAL_QUERY = gql`
    query getTotalMessages {
        getTotalMessages
    }
`;

export const MESSAGES_COUNT_BY_NETWORKTYPE = gql`
    query getMessagesCountByNetworkType {
        getMessagesCountByNetworkType {
            type
            count
        }
    }
`;

export const ACCOUNTS_COUNT_BY_ACCOUNTTYPE = gql`
    query getAccountsCountByType {
        getAccountsCountByType {
            type
            count
        }
    }
`;

export const ACTIVE_DEVICES_BY_DATERANGES = gql`
    query getActiveDevicesCountsByDate {
        getActiveDevicesCountsByDate {
            yesterday
            thirtyDays
            startOfMonth
        }
    }
`;

export const ACTIVE_USERS_BY_DATERANGES = gql`
    query getActiveUsersCountsByDate {
        getActiveUsersCountsByDate {
            yesterday
            thirtyDays
            startOfMonth
        }
    }
`;
