import { useState } from 'react';
import { Formik } from 'formik';

import { emptyFunction } from 'Utils';

import { Button } from '../../buttons';

import { IDeleteModal } from './interfaces';
import { FormContent } from './formContent';
import { initialValues, validationSchema } from './config';
import * as Styled from './styled';

export const DeleteModal: React.FC<IDeleteModal> = ({
    title,
    description,
    deleteAction,
    cancelAction,
    submitText,
    includeInput,
    checkingValue,
    isLoading,
}) => {
    const [isDisabled, setIsDisabled] = useState<boolean>(false);

    return (
        <Styled.Modal>
            <Styled.Title>
                {title}
            </Styled.Title>
            <Styled.Wrapper>
                <Styled.Description>
                    {description}
                </Styled.Description>
                {includeInput && (
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={emptyFunction}
                    >
                        {(props) => (
                            <FormContent
                                {...props}
                                checkingValue={checkingValue}
                                handleDisable={setIsDisabled}
                            />
                        )}
                    </Formik>
                )}
                <Styled.ButtonsWrapper>
                    <div>
                        <Button
                            buttonType="transparent"
                            onClick={cancelAction}
                        >
                            Cancel
                        </Button>
                    </div>
                    <div>
                        <Button
                            disabled={isDisabled}
                            onClick={deleteAction}
                            loading={isLoading}
                        >
                            {submitText}
                        </Button>
                    </div>
                </Styled.ButtonsWrapper>
            </Styled.Wrapper>
        </Styled.Modal>
    );
};

DeleteModal.defaultProps = {
    submitText: 'Delete',
    includeInput: false,
    checkingValue: '',
    isLoading: false,
};
