import { useQuery, QueryResult } from '@apollo/client';

import { DashboardCardStatsQueries, DashboardCardStats } from 'Types';
import { DASHBOARD_RELEASES_STATS } from 'Queries';
import { ReactComponent as ReleasesIcon } from 'Assets/icons/package.svg';

import { Card } from '../components';

export const ReleasesWidget: React.FC = () => {
    const { data: dashboardReleasesData }: QueryResult<DashboardCardStatsQueries> = useQuery(DASHBOARD_RELEASES_STATS);
    const dashboardReleasesStats = dashboardReleasesData?.getDashboardReleasesStats ?? {} as DashboardCardStats;

    return (
        <Card name="Releases" stats={dashboardReleasesStats} icon={<ReleasesIcon/>} />
    );
};
