import { ReactComponent as ArrowLeft } from 'Assets/icons/arrowLeft.svg';
import { ReactComponent as ArrowRight } from 'Assets/icons/arrowRight.svg';
import { emptyFunction } from 'Utils';

import { IPagination } from './interfaces';
import * as Styled from './styled';

export const Pagination: React.FC<IPagination> = ({ hasNextPage, hasPreviousPage, firstCursor, lastCursor, onChange }) => {
    const onPrev = () => {
        onChange({ before: firstCursor });
    };

    const onNext = () => {
        onChange({ after: lastCursor });
    };

    return (
        <Styled.PaginationWrapper>
            <Styled.PaginationButton
                disabled={!hasPreviousPage}
                onClick={onPrev}
            >
                <ArrowLeft />
                Prev
            </Styled.PaginationButton>
            <Styled.PaginationButton
                disabled={!hasNextPage}
                onClick={onNext}
            >
                Next
                <ArrowRight />
            </Styled.PaginationButton>
        </Styled.PaginationWrapper>
    );
};

Pagination.defaultProps = {
    hasNextPage: false,
    hasPreviousPage: false,
    firstCursor: '',
    lastCursor: '',
    onChange: emptyFunction,
};
