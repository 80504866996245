import { Field, FieldArray } from 'formik';

import { Button, TextInput } from 'Components';
import { ReactComponent as Plus } from 'Assets/icons/plus.svg';
import { ReactComponent as Trash } from 'Assets/icons/trash.svg';

import { ISupportedDevices } from './interfaces';
import * as Styled from './styled';

export const SupportedDevices: React.FC<ISupportedDevices> = ({ devices }) => (
    <FieldArray
        name="supportedDevices"
        render={(arrayHelpers) => (
            <div>
                {devices && !!devices.length ? (
                    devices.map((_device, index) => (
                        <Styled.AddibleField key={index}>
                            <Field
                                name={`supportedDevices.${index}`}
                                type="text"
                                placeholder="Enter your device name"
                                component={TextInput}
                            />
                            <Styled.AddibleFieldControls>
                                <Button onClick={() => arrayHelpers.insert(index + 1, '')} >
                                    <Plus />
                                </Button>
                                <Button buttonType="transparent" onClick={() => arrayHelpers.remove(index)} >
                                    <Trash   />
                                </Button>
                            </Styled.AddibleFieldControls>
                        </Styled.AddibleField>
                    ))
                ) : (
                    <Styled.Add>
                        <Button onClick={() => arrayHelpers.push('')}>
                            <Plus />
                        </Button>
                        <div>
                            Add device
                        </div>
                    </Styled.Add>
                )}
            </div>
        )}
    />
);

