import { gql } from '@apollo/client';

export const GET_DEVICE_AVAILABLE_TOPICS_QUERY = gql`
    query getDeviceTemplateTopics {
        getDeviceTemplateTopics {
            _id
            name
            label
            createdAt
        }
    }
`;

export const GET_DEVICE_TOPIC_TEMPLATE_BY_ID_QUERY = gql`
    query getDeviceTemplateTopicById($_id: String!) {
        getDeviceTemplateTopicById(_id: $_id) {
            _id
            name
            label
        }
    }
`;

export const CREATE_DEVICE_TOPIC_TEMPLATE_MUTATION = gql`
    mutation createDeviceTemplateTopic($input: DeviceTemplateTopicInput!) {
        createDeviceTemplateTopic(input: $input) {
            _id
            name
            label
        }
    }
`;

export const UPDATE_DEVICE_TOPIC_TEMPLATE_MUTATION = gql`
    mutation updateDeviceTemplateTopic($_id: String!, $input: DeviceTemplateTopicUpdateInput!) {
        updateDeviceTemplateTopic(_id: $_id, input: $input) {
            _id
            name
            label
        }
    }
`;

export const DELETE_DEVICE_TOPIC_TEMPLATE_MUTATION = gql`
    mutation deleteDeviceTemplateTopic($_id: String!) {
        deleteDeviceTemplateTopic(_id: $_id)
    }
`;
