import { gql } from '@apollo/client';

export const GET_SHIELD_TEMPLATES_QUERY = gql`
    query getShieldTemplates {
        getShieldTemplates {
            _id
            name
            type
            imgUrl
            sensors
            supportedDevices
            supportedNetworks
            unsupportedNetworks
        }
    }
`;

export const GET_SHIELD_ALLOWED_NETWORKS_QUERY = gql`
    query getShieldAllowedNetworks($shieldType: String!) {
        getShieldAllowedNetworks(shieldType: $shieldType) {
            allowedNetworks
        }
    }
`;

export const GET_SHIELD_TEMPLATE_BY_ID = gql`
    query getShieldTemplateById($_id: String!) {
        getShieldTemplateById(_id: $_id) {
            _id
            name
            type
            imgUrl
            sensors
            supportedDevices
            supportedNetworks
            unsupportedNetworks
        }
    }
`;

export const CREATE_SHIELD_TEMPLATE_MUTATION = gql`
    mutation createShieldTemplate($input: ShieldTemplateInput!) {
        createShieldTemplate(input: $input) {
            _id
            name
            type
            imgUrl
            sensors
            supportedDevices
            supportedNetworks
            unsupportedNetworks
        }
    }
`;

export const UPDATE_SHIELD_TEMPLATE_MUTATION = gql`
    mutation updateShieldTemplate($_id: String!, $input: ShieldTemplateUpdateInput!) {
        updateShieldTemplate(_id: $_id, input: $input) {
            _id
            name
            type
            imgUrl
            sensors
            supportedDevices
            supportedNetworks
            unsupportedNetworks
        }
    }
`;

export const DELETE_SHIELD_TEMPLATE_MUTATION = gql`
    mutation deleteShieldTemplate($_id: String!) {
        deleteShieldTemplate(_id: $_id)
    }
`;
