import React from 'react';

import { useAuthenticate } from 'Hooks';
import { SingleChildrenArgumentType } from 'Types';

import { Header, Sidebar } from '../components';

import * as Styled from './styled';

export const MainLayout: React.FC<SingleChildrenArgumentType> = ({ children }) => {
    const { loader, isLoading } = useAuthenticate();

    if (isLoading) {
        return loader;
    }

    return (
        <Styled.Page>
            <Sidebar />
            <Styled.Content>
                <Header />
                <Styled.Container>
                    {children}
                </Styled.Container>
            </Styled.Content>
        </Styled.Page>
    );
};
