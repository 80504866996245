import { ReactComponent as ExternalIcon } from 'Assets/icons/externalLink.svg';

import { generateIcon } from 'Utils';
import { ROUTES } from 'Constants';

import { Logo } from '../logo';
import * as Styled from './styled';

export const Sidebar = () => {
    const sidebarItems = [
        {
            title: 'Home',
            icon: 'home',
            route: ROUTES.main,
        },
        {
            title: 'Devices',
            icon: 'device',
            route: ROUTES.devices.main,
        },
        {
            title: 'Networks',
            icon: 'network',
            route: ROUTES.networks,
        },
        {
            title: 'Notifications',
            icon: 'bell',
            route: ROUTES.notifications,
        },
    ];

    const secondaryItems = [
        {
            title: 'Support',
            icon: 'support',
            link: false,
            route: 'support',
        },
        {
            title: 'Legal',
            icon: 'legal',
            link: true,
            route: process.env.REACT_APP_LEGAL_LINK,
        },
    ];

    return (
        <Styled.Sidebar>
            <Styled.SidebarContent>
                <Styled.SidebarTop>
                    <Styled.Logo>
                        <Logo />
                        <Styled.BurgerCollapse />
                    </Styled.Logo>
                    <ul>
                        {sidebarItems.map((item) => (
                            <Styled.PrimaryItem
                                to={item.route}
                                key={item.title}
                                data-cy={`sidebar-button-${item.icon}`}
                            >
                                <Styled.Content>
                                    {generateIcon(item.icon)}
                                    <Styled.SidebarTitle>
                                        {item.title}
                                    </Styled.SidebarTitle>
                                </Styled.Content>
                            </Styled.PrimaryItem>
                        ))}
                    </ul>
                    <Styled.Separator />
                    <ul>
                        {secondaryItems.map((item) => {
                            if (!item.link) {
                                return (
                                    <Styled.SecondaryItem
                                        to={item.route}
                                        key={item.title}
                                        data-cy={`sidebar-button-${item.icon}`}
                                    >
                                        <Styled.Content>
                                            <Styled.SidebarTitle>
                                                {item.title}
                                            </Styled.SidebarTitle>
                                        </Styled.Content>
                                    </Styled.SecondaryItem>
                                );
                            }
                            return (
                                <Styled.Link
                                    href={item.route}
                                    key={item.title}
                                    target="_blank"
                                    rel="noreferrer"
                                    data-cy={`sidebar-button-${item.icon}`}
                                >
                                    <Styled.Content>
                                        <Styled.SidebarTitle>
                                            {item.title}
                                        </Styled.SidebarTitle>
                                        <ExternalIcon />
                                    </Styled.Content>
                                </Styled.Link>
                            );
                        })}
                    </ul>
                </Styled.SidebarTop>
                <Styled.Footer>
                    {process.env.REACT_APP_HIDE_SIDEBAR_PYCOM_LOGO !== 'true' &&
                        <Styled.PycomLogo />}
                    <Styled.Copyright>
                        Copyright 2022
                    </Styled.Copyright>
                </Styled.Footer>
            </Styled.SidebarContent>
        </Styled.Sidebar>
    );
};
