import { Dropdown } from 'Components';
import { LTE, LORA } from 'Constants';
import { ColumnType } from 'Types';
import { generateDeviceNetworkIcon } from 'Utils';

import * as Styled from '../generalStyled';

export const getColumns = (
    editTemplate: (id: string) => void,
    deleteTemplate: (id: string) => void
): ColumnType[] => {
    const dropdownActions = [
        {
            executedFunction: editTemplate,
            title: 'Edit',
        },
        {
            executedFunction: deleteTemplate,
            title: 'Delete',
        },
    ];

    return [
        {
            dataIndex: 'imgUrl',
            title: 'Image',
            render: (data) => {
                const imgSrc = data as string;
                return <Styled.ImageView src={imgSrc} alt="device" />;
            },
        },
        {
            dataIndex: 'name',
            title: 'Name',
        },
        {
            dataIndex: 'type',
            title: 'Type',
        },
        {
            dataIndex: 'supportedNetworks',
            title: 'Networks',
            render: (data) => {
                const networks = data as string[];
                return networks.map((network) => (
                    <Styled.Network
                        key={network}
                        $isLTE={network === LTE}
                        $isLora={network === LORA}
                    >
                        {generateDeviceNetworkIcon(network)}
                    </Styled.Network>
                ));
            },
        },
        {
            title: 'Actions',
            dataIndex: '_id',
            width: '15%',
            render: (id) => {
                const receivedId = id as string;
                return (
                    <Dropdown
                        actions={dropdownActions}
                        id={receivedId}
                    />
                );
            },
        },
    ];
};
