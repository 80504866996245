import styled, { ThemeProps } from 'styled-components';

import { IconButton } from 'Components';
import { Theme } from 'Types';
import { shadowXs, shadowSmColored } from 'Styles';

export const FirstRow = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
`;
export const TwoCols = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
`;

export const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
`;

export const PageTitle = styled.h1`
    font-size: 2rem;
    font-weight: 600;
`;

export const Export = styled(IconButton)`
    border-radius: 0.5rem;
    border: 1px solid ${({ theme }: ThemeProps<Theme>) => theme.colors.border};
    padding: 0.75rem 1rem;
    background: #fff;
    ${shadowXs}
    transition: 0.2s;
    &:hover, &:focus, &:active {
        background: #fff;
        border-color: ${({ theme }: ThemeProps<Theme>) => theme.colors.active_light};
        ${shadowSmColored}
    }
`;
