import { SyntheticEvent } from 'react';

import { emptyFunction } from 'Utils';

import { ISelect } from './interfaces';
import * as Styled from './styled';

export const Select: React.FC<ISelect> = ({
    values,
    field,
    handleSelect,
    form,
    custom,
    type,
    selectedItem,
    placeholder,
    disabled,
}) => {
    const currentHandleSelect = (e: SyntheticEvent) => {
        const { value } = e.target as HTMLInputElement;
        if (field) {
            field.onChange(e);
        }
        handleSelect({ type, value });
    };

    const showError = () => {
        if (field && form.errors.length) {
            return (
                <Styled.Error $isAnimated={!!form.errors[field.name]}>
                    {form.errors[field.name]}
                </Styled.Error>
            );
        }
        return null;
    };

    const handleValues = () => {
        if (values) {
            if (typeof values[0] === 'object') {
                switch (type) {
                    case 'WiFi':
                        const wifiValues = values as {_id: string; ssid: string}[];

                        return wifiValues.map((item) => (
                            <option key={item._id} value={item._id}>
                                {item.ssid}
                            </option>
                        ));
                    case 'LTE':
                        const lteValues = values as {_id: string; name: string}[];

                        return lteValues.map((item) => (
                            <option key={item._id} value={item._id}>
                                {item.name}
                            </option>
                        ));
                    default:
                        const defaultValues = values as {value: string; label: string}[];
                        const result = defaultValues.map((item) => (
                            <option key={item.value} value={item.value}>
                                {item.label}
                            </option>
                        ));

                        if (placeholder) {
                            result.unshift(
                                <option
                                    key="placeholder"
                                    value=""
                                    disabled
                                    hidden
                                >
                                    {placeholder}
                                </option>
                            );
                        }
                        return result;
                }
            } else {
                const primitiveValues = values as string[] | number[];
                return primitiveValues.map((item: string | number) => (
                    <option key={item} value={item}>
                        {item}
                    </option>
                ));
            }
        }
    };

    const onClick = (e: SyntheticEvent) => {
        e.stopPropagation();
    };

    return (
        <Styled.Select>
            <Styled.Field
                value={field ? field.value : selectedItem}
                name={field ? field.name : ''}
                onBlur={field ? field.onBlur : emptyFunction}
                onChange={custom ? currentHandleSelect : field.onChange}
                disabled={disabled}
                onClick={onClick}
            >
                {handleValues()}
            </Styled.Field>
            <div>{showError()}</div>
        </Styled.Select>
    );
};

Select.defaultProps = {
    custom: false,
    handleSelect: emptyFunction,
    field: null,
    form: null,
    type: '',
    selectedItem: '',
    placeholder: '',
    disabled: false,
};
