import { useQuery, QueryResult } from '@apollo/client';

import { DEVICES_COUNTS_BY_TYPES_QUERY } from 'Queries';
import { CountByTypeQueries, CountByType } from 'Types';

import { BarChart } from '../../../components';

export const DevicesBarChart: React.FC = () => {
    const { data }: QueryResult<CountByTypeQueries> = useQuery(DEVICES_COUNTS_BY_TYPES_QUERY);
    const chartData = data?.getDevicesCountsByTypes ?? [] as CountByType[];

    return (
        <BarChart
            chartData={chartData}
            nameKey="type"
            dataKey="count"
        />
    );
};
