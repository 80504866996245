import { Table } from 'Components';

import { INotificationTable } from './interfaces';
import { getColumns } from './columns';

export const NotificationsTable: React.FC<INotificationTable> = ({ data, editNotificationHandler }) => {
    const columns = getColumns(editNotificationHandler);
    return (
        <Table
            data={data}
            columns={columns}
            wrappedTable={true}
        />
    );
};
