import { UserProfile } from './components';
import * as Styled from './styled';

export const Header: React.FC = () => (
    <Styled.Header>
        <Styled.Menu>
            <UserProfile />
        </Styled.Menu>
    </Styled.Header>
);
