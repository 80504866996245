import styled, { ThemeProps } from 'styled-components';

import { Theme } from 'Types';

type TitleProps = {
    $withIcon: boolean;
};

export const Item = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Top = styled.div`
    display: flex;
    align-items: center;
`;

export const Title = styled.div`
    display: flex;
    align-items: center;

    & div:last-child {
        margin: 0 71px 0 20px
    }

    ${({ $withIcon }: TitleProps) => !$withIcon && `
        & div:last-child {
            margin-left: 0;
        }
    `}
`;

export const Bottom = styled.div`
    margin-top: 20px;
`;

export const PageTitle = styled.div`
    color: ${({ theme }: ThemeProps<Theme>) => theme.colors.title};
    font-size: 36px;
    font-weight: bold;
`;

export const PageDescription = styled.div`
    color: ${({ theme }: ThemeProps<Theme>) => theme.colors.text};
    font-size: 16px;
`;

export const BlockWrapper = styled.div`
    width: 40px;
    height: 5px;
    display: flex;
`;

export const ColoredSpan = styled.span`
    width: 20px;
    background-color: ${({ theme }: ThemeProps<Theme>) => theme.colors.active};
`;

export const BackgroundSpan = styled.span`
    width: 20px;
    background-color: ${({ theme }: ThemeProps<Theme>) => theme.colors.border};
`;
