import styled, { ThemeProps } from 'styled-components';
import { NavLink } from 'react-router-dom';

import { Theme } from 'Types';

export const Wrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`;

export const List = styled.div`
    display: flex;
    width: 100%;
    border-bottom: 1px solid ${({ theme }: ThemeProps<Theme>) => theme.colors.border};
    padding: 0.5rem 0;
`;

export const Item = styled(NavLink)`
    display: block;
    padding: 0.75rem 1.5rem;
    color: ${({ theme }: ThemeProps<Theme>) => theme.colors.title};
    position: relative;
    font-size: 0.875rem;
    font-weight: bold;

    &:hover {
        color: ${({ theme }: ThemeProps<Theme>) => theme.colors.active};
    }

    &.active {
        color: ${({ theme }: ThemeProps<Theme>) => theme.colors.active};

        &::after {
            content: '';
            position: absolute;
            left: 0;
            bottom: -10px;
            width: 100%;
            height: 4px;
            background-color: ${({ theme }: ThemeProps<Theme>) => theme.colors.active};
        }
    }
`;
