import styled, { ThemeProps } from 'styled-components';
import { Theme } from 'Types';
import { Button } from 'Components';

export const Modal = styled.div`
    padding: 2rem;
    &> form > * + * {
        margin-top: 1rem;
    }
`;

export const Header = styled.div`
    padding: 1rem 1.25rem;
    background-color: ${({ theme }: ThemeProps<Theme>) => theme.colors.text};
    color: ${({ theme }: ThemeProps<Theme>) => theme.colors.activeText};
    font-weight: 700;
    font-size: 1rem;
`;

export const Item = styled.div`
    width: 100%;
    input:not([type="checkbox"]), textarea {
        width: 100%;
    }
`;

export const Label = styled.label``;

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1rem;
`;

export const Delete = styled(Button)`
    background-color: transparent;
    color: ${({ theme }: ThemeProps<Theme>) => theme.colors.error};
    &:hover {
        background-color: transparent;
        color: ${({ theme }: ThemeProps<Theme>) => theme.colors.error};
    }
`;

