import styled, { css, ThemeProps } from 'styled-components';
import { Button as AntButton } from 'antd';

import { Theme } from 'Types';

type ButtonType = ThemeProps<Theme> & {
    $buttonType: 'transparent' | 'filled';
    $bordered: boolean;
    $textBold: boolean;
};

const borderedStyles = css`
    border: 1px solid ${({ theme }: ThemeProps<Theme>) => theme.colors.border};

    &:hover,
    &:focus {
        color: ${({ theme }: ThemeProps<Theme>) => theme.colors.title};
        border-color: ${({ theme }: ThemeProps<Theme>) => theme.colors.border};
    }
`;

const filledButtonStyles = css`
    background: ${({ theme }: ThemeProps<Theme>) => theme.colors.active};
    border: 1px solid ${({ theme }: ThemeProps<Theme>) => theme.colors.active};
    color: ${({ theme }: ThemeProps<Theme>) => theme.colors.activeText};

    &:hover,
    &:focus {
        background: ${({ theme }: ThemeProps<Theme>) => theme.colors.active};
        color: ${({ theme }: ThemeProps<Theme>) => theme.colors.activeText};
        border-color: ${({ theme }: ThemeProps<Theme>) => theme.colors.active};
    }
`;

const boldTextStyles = css`
    font-weight: bold;
`;

export const Button = styled(AntButton)`
    height: auto;
    display: flex;
    align-items: center;
    padding: 0.5rem 0.75rem;
    color: ${({ theme }: ThemeProps<Theme>) => theme.colors.title};
    transition: color 0.3s;
    font-size: 14px;
    cursor: pointer;
    background: transparent;
    border-radius: 0.5rem;
    border-color: transparent;
    box-shadow: none;

    svg {
        margin-right: 10px;
        font-size: 20px;
    }

    &:hover,
    &:focus {
        color: ${({ theme }: ThemeProps<Theme>) => theme.colors.active};
        border-color: transparent;
        box-shadow: none;
        background-color: transparent;
    }

    ${({ $buttonType }: ButtonType) => $buttonType === 'filled' && filledButtonStyles}
    ${({ $bordered }: ButtonType) => $bordered && borderedStyles}
    ${({ $textBold }: ButtonType) => $textBold && boldTextStyles}
`;
