import { Formik } from 'formik';
import { useMutation } from '@apollo/client';

import { showToastError, showToastSuccess } from 'Utils';
import {
    GET_ALL_SYSTEM_NOTIFICATIONS,
    CREATE_SYSTEM_NOTIFICATION,
    EDIT_SYSTEM_NOTIFICATION,
    DELETE_SYSTEM_NOTIFICATION,
} from 'Queries';
import {
    AddSystemNotificationMutationType,
    UpdateSystemNotificationMutationType,
    Notification,
} from 'Types';

import { FormContent } from './formContent';
import { IModal } from './interface';
import { getInitialValues } from './helpers';
import * as Styled from './styled';
import { validationSchema } from './config';

export const NotificationsModal: React.FC<IModal> = ({ notification, handleClose }) => {
    const [addSystemNotification] = useMutation<AddSystemNotificationMutationType>(CREATE_SYSTEM_NOTIFICATION, {
        refetchQueries: [GET_ALL_SYSTEM_NOTIFICATIONS],
    });
    const [editSystemNotification] = useMutation<UpdateSystemNotificationMutationType>(EDIT_SYSTEM_NOTIFICATION, {
        refetchQueries: [GET_ALL_SYSTEM_NOTIFICATIONS],
    });
    const [deleteSystemNotification] = useMutation(DELETE_SYSTEM_NOTIFICATION, {
        refetchQueries: [GET_ALL_SYSTEM_NOTIFICATIONS],
    });

    const saveNotificationHandler = async ({
        title,
        content,
        type,
        active,
    }: Notification): Promise<void> => {
        if (!notification.systemNotificationId) {
            try {
                await addSystemNotification({
                    variables: {
                        systemNotification: {
                            title,
                            content,
                            type,
                            active: active || false,
                        },
                    },
                    update: () => {
                        handleClose();
                        showToastSuccess('Notification created successfully.');
                    },
                });
            } catch (error) {
                showToastError(error.toString());
            }
        } else {
            try {
                await editSystemNotification({
                    variables: {
                        id: notification.systemNotificationId,
                        systemNotification: {
                            title,
                            content,
                            type,
                            active: active || false,
                        },
                    },
                    update: () => {
                        handleClose();
                        showToastSuccess('Notification saved successfully.');
                    },
                });
            } catch (error) {
                showToastError(error.toString());
            }
        }
    };

    const handleDelete = async (): Promise<void> => {
        try {
            await deleteSystemNotification({
                variables: { id: notification.systemNotificationId },
                update: () => {
                    handleClose();
                    showToastSuccess('Notification deleted successfully.');
                },
            });
        } catch (error) {
            showToastError(error.toString());
        }
    };

    return (
        <>
            <Styled.Header>
                    Add new Notification
            </Styled.Header>
            <Styled.Modal>
                <Formik
                    enableReinitialize
                    initialValues={getInitialValues(notification)}
                    onSubmit={saveNotificationHandler}
                    validationSchema={validationSchema}
                >
                    {(props) => (
                        <FormContent
                            {...props}
                            handleClose={handleClose}
                            handleDelete={handleDelete}
                        />
                    )}
                </Formik>
            </Styled.Modal>
        </>
    );
};
